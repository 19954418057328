import { Injectable } from '@angular/core';
import { User } from '../models/users.model';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { SitesService } from 'src/app/main-nav/sites/sites.service';
import { centroid } from '@turf/turf';
const BACKENDURL = environment.apiURL

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  currentUser: User
  // {
  //   username: 'Dhawal',
  //   id: '1',
  //   email: 'dhawalgaur17@gmail.com'
  // }
  private token: string;
  tokenTimer: NodeJS.Timer
  isLoggedIn: Subject<boolean>= new Subject()
  constructor(private http: HttpClient,private router: Router, private route: ActivatedRoute,private snackbar: MatSnackBar, private siteService: SitesService) { }

  registerUser(user: User){
    return this.http.post<{message: string, id: string}>(BACKENDURL+'/users', user)
  }

  initiateResetPassword(data: Object){
    return this.http.post<{message: string}>(BACKENDURL+'/users/resetmail', data)
  }

  saveUser(user: User){
    console.log(user)
    this.currentUser = user;
    console.log(this.currentUser)
  }

  saveToken(token: string){
    this.token = token
    if(token){
      this.isLoggedIn.next(true)
    }else{
      this.isLoggedIn.next(false)
      clearTimeout(this.tokenTimer)
      this.clearAuthData()
    }
  }

  getUser(){
    return this.currentUser;
  }

  getToken(){
    return this.token
  }

  login(user){
    this.http.post<{message: string, token?: string, timer?: number, user?: User}>(BACKENDURL+'/users/login', user).subscribe(res=>{
      console.log(res.user);  
      if(res.token){
        console.log(res.user)
        this.saveUser(res.user)
        this.saveToken(res.token)
        this.tokenTimer = setTimeout(() => {
          this.saveToken(null)
        }, res.timer *1000);
        const now = new Date()
        const expirationDate = new Date(now.getTime() + res.timer*1000)
        this.saveAuthData(res.token, expirationDate, res.user)
        console.log(res.user)
         if(res.user.organization === 'ADMIN'){
          // 
          if(res.user.email == 'vishvaraj@heliware.co.in'){
            // this.router.navigate(['dashboard/admin-dashboard/project-dashboard'], {relativeTo: this.route})
            this.router.navigate(['/dashboard/admin-home'], {relativeTo: this.route})
          }else{
            this.router.navigate(['/new-dashboard'], {relativeTo: this.route})
          }
          
          
          
        }
        else{
          this.router.navigate(['dashboard/sites'], {relativeTo: this.route}).then(val=>{
          })
        }
      }else{
        this.saveToken(null)
        this.snackbar.open('Wrong Credentials','Dismiss',{
          duration: 3000
        })
      }
    },err=>{
      this.saveToken(null)
      this.snackbar.open(err.error.message,'Dismiss',{
        duration: 3000
      })
    })

    // this.siteService.getProject(res=>{
    //   console.log(res);
      
    // })

  
  //  if(user){
  //   // const siteDet =  this.siteService.fetchSites(user.id);
  //   // console.log(siteDet);
  // }
  }

  autoAuth(){
    const authInformation = this.getAuthData()
    if(authInformation){
      const now = new Date
      const expiresIn = authInformation.expirationDate.getTime() - now.getTime()
      if(expiresIn> 0){
        this.saveToken(authInformation.token)
        this.saveUser(authInformation.user)
        this.tokenTimer = setTimeout(() => {
          this.saveToken(null)
        }, expiresIn);
        // if(authInformation.user.email === 'jmc@sgrlabs.co.in'){
        //   // this.router.navigate(['dashboard/region'], {relativeTo: this.route})
        // }else{
        //   // this.router.navigate(['dashboard/sites'], {relativeTo: this.route})
        // }
      }
    }
  }

  private saveAuthData(token: string, expiresIn: Date, user: User){
    localStorage.setItem('token', token)
    localStorage.setItem('expirationDate', expiresIn.toISOString())
    localStorage.setItem('username', user.username)
    localStorage.setItem('id', user.id)
    localStorage.setItem('email', user.email)
    localStorage.setItem('poiCreator', user.poiCreator?'true':'false')
    localStorage.setItem('organization', user.organization)
    localStorage.setItem('usertype', user.userType)
    localStorage.setItem('title', user.projectTitle)
    localStorage.setItem('logo', user.projectLogo)
  }

  private clearAuthData(){
    localStorage.removeItem('token')
    localStorage.removeItem('expirationDate')
    localStorage.removeItem('username')

    localStorage.removeItem('id')
    localStorage.removeItem('email')
    localStorage.removeItem('poiCreator')

    localStorage.removeItem('organization')
    localStorage.removeItem('usertype')
    localStorage.removeItem('logo')
    localStorage.removeItem('title')
  }

  getAuthData(){
    const token = localStorage.getItem('token')
    const expirationDate = localStorage.getItem('expirationDate')
    const username = localStorage.getItem('username')
    const id = localStorage.getItem('id')
    const email = localStorage.getItem('email')
    const poiCreator = localStorage.getItem('poiCreator') == 'true'?true:false;
    const organization = localStorage.getItem('organization')
  
    if(!token || !expirationDate){
      return
    }
    return {
      token,
      expirationDate: new Date(expirationDate),
      user: {
        username, email, id, poiCreator, organization
      }
    }
  }

  resetPassword(userdata){
    return this.http.post<{message: string}>(`${BACKENDURL}/users/resetpassword`, userdata)
  }

  findUser(email: string){
    return this.http.post<{message: string, user: User}>(`${BACKENDURL}/users/find`, {email})
  }

  findUserById(id: string){
    return this.http.get<{message: string, user: User}>(`${BACKENDURL}/users/find/${id}`);
  }

  findUsers(ids: string[]){
    return this.http.post<{message: string, users: User[]}>(`${BACKENDURL}/users/find/multiple`, {ids})
  }

}
