import { feature, length } from '@turf/turf';
// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-new-admin-dashboard',
//   templateUrl: './new-admin-dashboard.component.html',
//   styleUrls: ['./new-admin-dashboard.component.scss']
// })
// export class NewAdminDashboardComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }
import { Component, OnInit,Input, Output, EventEmitter, ElementRef, ViewChild, OnDestroy, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import * as uuid from 'uuid';
import { MapService } from '../map.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Route, Router } from '@angular/router';
import { SitesService } from 'src/app/main-nav/sites/sites.service';
import { User } from 'src/app/models/users.model';
import { Site } from 'src/app/models/sites.model';
import { catchError, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// import { Chart } from 'chart.js'
import { ServerService } from 'src/app/server.service';
import * as echarts  from 'echarts';
import { MatSnackBar } from '@angular/material/snack-bar';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
// import { DialogCommentComponent } from './dialog-comment/dialog-comment.component';
import { element } from 'protractor';
import { featureIcons } from 'src/app/model-on-map/geospatial.interfece';
import * as turf  from '@turf/turf'
import * as $ from 'jquery';
import { SlideInOutAnimation } from '../../animations';
import { PlaceSearchService } from 'src/app/heli-ai/place-search.service';
import { HttpClient } from '@angular/common/http';
import { Light } from 'three';


interface HierarchyNode {
  part: string;
  children: HierarchyNode[];
  data?: any
}

// @Component({
//   selector: 'app-admin-home',
//   templateUrl: './admin-home.component.html',
//   styleUrls: ['./admin-home.component.scss']
// })
// export class AdminHomeComponent implements OnInit, OnDestroy{
@Component({
  selector: 'app-new-admin-dashboard',
  templateUrl: './new-admin-dashboard.component.html',
  styleUrls: ['./new-admin-dashboard.component.scss'],
  animations: [SlideInOutAnimation]
})
export class NewAdminDashboardComponent implements OnInit {
  @Input() inputData: any;

 
  showProjectTables: boolean = true;
  showRunningStatus: boolean =false;
  currentUser: string;
  tableData:any[] = [];
  overAllTabledata = [
    // Example data structure
    { Department: 'Light', data: '...' },
    { Department: 'PWD', data: '...' }
  ];
  siteId: any 
  // overAllTabledata:any
  tableHeader: string[];
  totalProjectStarted: any;
  budgetSanctioned:any = 0;
  budgetUtilised:any = 0;
  budgetTransferred:any = 0;
  projectStarted = 0;
  projectRetendered= 0;
  projectChange= 0;
  totalPlannedRoad = 0;
  totalCompleteRoad = 0;
  dropdownProjects = [];
  tempCompletedObj: any = {};
  kmlData :any = [];
  siteArray: any= [];
  totalPoles: any = 0;
  totalBulbs: any = 0;
  pwdCount: number = 0;
  Light:number = 0;
  lightBridgeCount :number =0;
  animationState = 'out';
  animationStates = 'out';
  animationStat ='out';
  completionChart ='out';
  animationStat2 =' out';
  animationState1='out';
  animationState2='out';
  animationState3='out';
  animationState4='out';
  animationState5='out';
  animationState6='out';
  animationState7='out';
  animationState8='out';
  animationState9='out';
  animationState10='out';
  completion:boolean= false;
  completion1:boolean= false;
  completion2:boolean= false;
  completion3:boolean= false;
  completion4:boolean= true;
  completion5:boolean= true;
  tables:boolean= false;
  showButton: boolean = false;
  hideButten: boolean = true;
  featureObject: {};
  previousSelectedProject: string;
  Category : any;
  TotalRunningProjectsLight:number = 0;
  TotalRunningProjectsPwd:number = 0;
  TotalRunningProjectsLightBridge: number =0;
  placeHint: string = "";
  resultPlace: any[] = [];
  organization: string;
  Counts = 0;
  assignedProjects: any = [];
  temp: any[];
  @Output() SideNavToggle = new EventEmitter();
    getCurrentDateTime(): string {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleString();
      return formattedDate;
    }

  


  @Output() closeSideNav = new EventEmitter();
  @Output() childEvent = new EventEmitter<boolean>();
  
  

  @ViewChild('gmap', { static: true }) gMapElement: ElementRef;
  targetArraytemp: any=[];

  chartpwd:boolean = true;
  pwdCharts: boolean = true;
  projectCharts :boolean =false;
  months = [];
  days:any = {};
  chart1:boolean = false;
  chart2:boolean= false;
  chart3:boolean= false;
  chart4:boolean= false;
  chart5:boolean= false;
  chart6:boolean= false;
  chart7:boolean= false;
  chart8:boolean= false;
  chart9:boolean= false;
  chart10:boolean= false;
  chart11:boolean= false;
  showDiv:boolean =true;
  isVisible: boolean = true;
  isNotVisible: boolean = false;
  showStyle :boolean =false;
  chart :boolean =false;
  showPWD: boolean = true;
  showLight: boolean = true;
  showBridge:boolean =false;
  showProject: boolean = true;
  showBudget: boolean = false;
  icon: any;
  usersGraph: any;
  map: google.maps.Map<any>;
  map1: google.maps.Map;
  
  mappanel1: google.maps.Map;
  mappanel2: google.maps.Map;
  mappanel3: google.maps.Map;
  mappanel4: google.maps.Map;
  mappanel5: google.maps.Map<any>;
     darkModeStyle: google.maps.MapTypeStyle[] = [
    { "elementType": "geometry", "stylers": [{ "color": "#212121" }] },
    { "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] },
    { "elementType": "labels.text.fill", "stylers": [{ "color": "#757575" }] },
    { "elementType": "labels.text.stroke", "stylers": [{ "color": "#212121" }] },
    { "featureType": "administrative", "elementType": "geometry", "stylers": [{ "color": "#757575" }] },
    { "featureType": "administrative.country", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] },
    { "featureType": "administrative.land_parcel", "stylers": [{ "visibility": "off" }] },
    { "featureType": "administrative.locality", "elementType": "labels.text.fill", "stylers": [{ "color": "#bdbdbd" }] },
    { "featureType": "poi", "elementType": "labels.text.fill", "stylers": [{ "color": "#757575" }] },
    { "featureType": "poi.park", "elementType": "geometry", "stylers": [{ "color": "#181818" }] },
    { "featureType": "poi.park", "elementType": "labels.text.fill", "stylers": [{ "color": "#616161" }] },
    { "featureType": "poi.park", "elementType": "labels.text.stroke", "stylers": [{ "color": "#1b1b1b" }] },
    { "featureType": "road", "elementType": "geometry.fill", "stylers": [{ "color": "#2c2c2c" }] },
    { "elementType": "labels.text.fill", "stylers": [{ "color": "#8a8a8a" }] },
    { "featureType": "road.arterial", "elementType": "geometry", "stylers": [{ "color": "#373737" }] },
    { "featureType": "road.highway", "elementType": "geometry", "stylers": [{ "color": "#3c3c3c" }] },
    { "featureType": "road.highway.controlled_access", "elementType": "geometry", "stylers": [{ "color": "#4e4e4e" }] },
    { "featureType": "road.local", "elementType": "labels.text.fill", "stylers": [{ "color": "#616161" }] },
    { "featureType": "transit", "elementType": "labels.text.fill", "stylers": [{ "color": "#757575" }] },
    { "featureType": "water", "elementType": "geometry", "stylers": [{ "color": "#000000" }] },
    { "featureType": "water", "elementType": "labels.text.fill", "stylers": [{ "color": "#3d3d3d" }] }
  ];
  loading: any = false
  ShapeLayerData: any = [];
  allDetectionProjectResp: Array<any> = [];
  data: google.maps.Data;
  creator: User;
  creatorid: string;
  sites: Site[];
  projects: any = [];
  loggedInUser: any
  // siteId: string
  unsubscribeSubject = new Subject();
  selectedSite: any;
  selectedProject: any;
  form: any;
  parentData: {}
  totalProgress: any;
  resultHierarchy: any[] = [];
  achivedTotalResult = 0;
  mileStoneTotalResult = 0;
  achivedArray = [];
  mileStoneArray = [];
  spinner= false;
  showChart : boolean = false
  savedItems: string[] = [];
  filteredItems : any[]=[];
  searchProject: boolean = false;
  users:any
  // showChart : boolean = false;
  userControl = new FormControl();
  filteredUsers: Observable<string[]> = of([]);
  projectsTargetData: string[] = [];
  projectsPercentageArray = [];
  userId : false;
  showProjectChart:boolean =false;
  showNoProject:boolean =false;
  showProjectTable: boolean = true;
  categoryChart : boolean = false
  commentForm: FormGroup;
  editComment = false;
  userComments: any;
  reply = false;
  selectedTableData: any;
  totalProjectDays: number; 
  projectName : string;
  excelNotFound = false;
  projectSummary :boolean=true;
  totalCount = 0;
  plannedRoad:any;
  selectProject:any;
  selectedUser: any;
  // exceldata: any;
  completionDate:any=[]
  showCompletionChart = false
    obj :any
    totalLength = 0;
    currentDate = new Date()
  constructor(
    private dialog: MatDialog,
    // private dialog: MatDialog,
    private mapService: MapService,
    private auth: AuthService,
    private router: Router,
    private sitesService: SitesService,
    private formBuilder: FormBuilder,
    private server: ServerService,
    private snackbar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    public placeSearch: PlaceSearchService,
    private http: HttpClient
  ) {
    this.currentUser = localStorage.getItem('email')
    
   }

   targetData: string[] = [];
   plannedData = [];
   achivedData = [];
   projectPlannedResult = [];
   progressPlannedResult = [];
   projectAchivedResult = [];
   selectedPlannedResult = [];
   selectedAchivedResult = [];
   onTimeProject = [];
   projectStartDate: Date;
   projectEndDate: Date;
   weeksChart = false;
   monthlyAchive = [];
   monthlyTraget = [];
  //  months = [];
   monthsObject = {};
   excelResult : any;
   selectedMonth: string;
   backWeeksChart = false;
   innerChart = false;
   excelNotUploaded = false;
   newProjectPlannedResult = [];
   newProjectAchivedResult = [];
   parentTableData :boolean;
   selectedProcess = "twentyFive";
   percentage:number = 25;
   private myChart: any;
   private interval: any;
 
   tempKmlData: any =[]
  

  ngOnChanges(): void {
    console.log(this.inputData.projectId,"this.inputData.projectId")
    this.showProjectChart=true;
    this.mapService.getProject(this.inputData.projectId)
    // let temp = []
    this.mapService.onceProjectChanged.subscribe(data => {

      this.completionDate =data.completionDate
      this.kmlData = data.kmlData
      this.tempKmlData =data.tempKmlData



      data.kmlData.forEach(element => {
        console.log(element.name)
        if(element.name.includes('Planned')){
          this.plannedRoad = element.measurement
          // this.plannedRoad = 2
        }
      });
     
      this.excelData()
    })
   
  }

  ngOnInit(): void {
    
    this.http.get('https://nominatim.openstreetmap.org/search?q=prayagraj&format=json').subscribe((res:any) => {
      console.log(res,"res====>")
      let placeId
      res.forEach((e:any) => {
        console.log(e)
        if(JSON.stringify(e.boundingbox) == JSON.stringify(['24.8046120', '25.7538991', '81.5170177', '82.3527339'])){
          placeId = e.place_id;
          this.mapService.fetchPlaceDetails(placeId).subscribe(
            (response) => {
              console.log('API Response:', response.geometry);
              this.mapService.searchOnMapData.next(response.geometry);    
            },)
        }
      })
      console.log(placeId)
       // Replace with your place_id
      
    });
    // console.log(id,'dsadsadsada')
    this.organization = localStorage.getItem('organization');
    console.log(this.organization,"this.organization")
   
    setInterval(() => {
      this.currentDate = new Date();
    }, 1);

    this.icon = this.mapService.featureIcons;
    this.creator = this.auth.getUser();
    // console.log("this.creator ==================>>>>",this.creator)
    this.creatorid = this.creator.id;
    // this.getProjectOverviewData().then(()=>{
      let temp = '';
      if (this.organization === "ADMIN" ) {
        temp = 'Pmis';
        this.fetchSiteData();

      } 
      else {
        // temp = " ";
        this.fetchAssignedData();
      }

      console.log(this.assignedProjects)
    // })
    // this.form = this.formBuilder.group({
    //   'selectedSite': new FormControl('', Validators.required),
    //   'selectedProject': new FormControl({ value: '', disabled: true }, Validators.required)
    // });
    this.form = this.formBuilder.group({
      selectedSite: [''],
      selectedProject: ['']
    });
    this.mapSetup();
    this.mapService.getAllDetectionProjects({ userId: this.creatorid }).then((response) => {
      this.allDetectionProjectResp = response['data'];
      // console.log(response,"this.allDetectionProjectResp")
    })
    this.mapService.spinner.on('loader', (load => {
      // console.log(load)
      this.loading = false;
    }))

    // this.createSiteCountChart();
    this.commentForm = this.formBuilder.group({
      name: [{value:'',disabled: this.editComment}, [Validators.required, ]],
      message: ['', [Validators.required]],
      projectId: ['']
    });
    if (this.commentForm) {
      this.filteredUsers = this.commentForm.get('message')!.valueChanges.pipe(
        startWith(''),
        map(value => this.filterUsers(value))
      );
    }
    this.getUserComments()
  }
  
  excelData() {  
    console.log(this.inputData,'inputs')
    this.days.chartData=false
    this.childEvent.emit(this.days)
    this.targetData = [];
    this.months = [];
    this.server.getSelectedItem(this.inputData).pipe(
      switchMap((resp: any) =>  {
       return this.mapService.getXLSXData(resp.data[0]?.table)
      })
    ).subscribe((responses) => {
      this.excelResult = responses;
      if(responses?.features){
        this.excelNotUploaded = false;
        this.projectPlannedResult =[] 
        this.progressPlannedResult = [];
        this.projectAchivedResult = []
        this.targetArraytemp = []
        const order = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        const currentDate = new Date();
        const currentMonth = currentDate.toLocaleString('en-US', { month: 'short' });
        const currentYear = currentDate.getFullYear();
        const monthProperties = Object.keys(responses.features[0]?.properties).filter(key => key.includes("'"));
        this.weeksChart = true;
        const [weekPart, _, month, yearPart] = monthProperties[0].split(/['.\s]/);
        const yearly = yearPart.length === 4 ? yearPart : `20${yearPart}`;
        const firstMonth = month;
        const firstYear = yearly;
        const firstWeek = parseInt(weekPart);
        const firstDayOfMonth = new Date(`${firstMonth} 01, ${firstYear}`);
        const dayOfWeek = firstDayOfMonth.getDay();
        const firstDayOfTargetWeek = new Date(firstDayOfMonth);
        firstDayOfTargetWeek.setDate(firstDayOfMonth.getDate() - dayOfWeek + firstWeek * 7);
        const [lastWeekPart, __, lastMonth, lastYearPart] = monthProperties[monthProperties.length - 1].split(/['.\s]/);
        const lastYearly = lastYearPart.length === 4 ? lastYearPart : `20${lastYearPart}`;
        const lastMonth1 = lastMonth;
        const lastYear1 = lastYearly;
        const lastWeek1 = parseInt(lastWeekPart);
        console.log(lastWeek1)
        // Calculate the end date of the last week
        const lastDayOfMonth = new Date(`${lastMonth1} 01, ${lastYear1}`);
        const lastdayOfWeek = lastDayOfMonth.getDay();
        const lastDayOfTargetWeek = new Date(this.completionDate);
        lastDayOfTargetWeek.setDate(lastDayOfMonth.getDate() - lastdayOfWeek + lastWeek1 * 7);
        lastDayOfTargetWeek.setDate(lastDayOfTargetWeek.getDate() + 6);

        this.projectStartDate = firstDayOfTargetWeek;
        this.projectEndDate = lastDayOfTargetWeek;

        // this.firstDayOfTargetWeek.emit(firstDayOfTargetWeek);
        // this.lastDayOfTargetWeek.emit(lastDayOfTargetWeek);
        this.days.projectStartDate = this.projectStartDate;
        this.days.projectEndDate = this.projectEndDate;
        this.childEvent.emit(this.days)
        
        console.log(this.projectStartDate,'this.completionDate')
        // this.projectEndDate =this.completionDate;
        // Calculate the difference in milliseconds
        const timeDifference = lastDayOfTargetWeek.getTime() - new Date().getTime();
        // console.log(Math.ceil(lastDayOfTargetWeek.getTime() / (1000 * 60 * 60 * 24 * 7)) ,firstDayOfTargetWeek,  lastDayOfTargetWeek)
        // Convert milliseconds to days
        const weeksLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24 * 7));
        const totalWeeks = Math.ceil((lastDayOfTargetWeek.getTime() - firstDayOfTargetWeek.getTime()) / (1000 * 60 * 60 * 24 * 7))

        console.log(totalWeeks,'totalweeks')
        let tillCurrentWeek = false;
        let tillCurrentMonth = false
        let tillCurrentMonthArray = [];
      // const header = responses.find((resp)=> resp?.features?.length)
        for (const key in responses.features[0]?.properties) {

          const parts = key.split("'");
          const monthYearPart = parts[0].split(' ');
          const month = monthYearPart[monthYearPart.length - 1];
          const year = parts[1];


          if (year !== undefined) {
            const numericalPart = year.match(/\d+/g).map(Number);
            if (numericalPart[1] === undefined) {
              numericalPart[1] = 0
            }
          }
          if (
            (parseInt(year, 10) + 2000 <= currentYear)
          ) {
          if (parseInt(year, 10) + 2000 == currentYear) {
              const currentWeekIndex = Math.ceil(currentDate.getDate() / 7);
              const findCurrentMonthIndex = order.findIndex((res) => res == currentMonth)
              const findMonthIndex = order.findIndex((res) => res == month)
              const numericPart = monthYearPart[0].match(/\d+/);
              const result = Number(numericPart[0]);
              if(!tillCurrentWeek){
                this.targetData.push(key)
              } 
              if (findCurrentMonthIndex === findMonthIndex && ((result === currentWeekIndex) ||  (result === 4 && currentWeekIndex === 5)) ) {
                tillCurrentWeek = true;
              }
              if(findCurrentMonthIndex+1 === findMonthIndex ){
                tillCurrentMonth = true; 
              }

              if(!tillCurrentMonth){
                tillCurrentMonthArray.push(key)
              }

            }
             else {
              if(!tillCurrentWeek){
                this.targetData.push(key)
              }
              if(!tillCurrentMonth){
                tillCurrentMonthArray.push(key)
              }
            }

          }
        }
        const monthsObj:any = {};

        tillCurrentMonthArray.forEach(item => {
          const parts = item.split(" ");
          const lastPart = parts[parts.length - 1].split("'").join("-")
          if (!monthsObj[lastPart]) {
            monthsObj[lastPart] = [];
          }
          monthsObj[lastPart].push(item);
        });
        this.months = Object.keys(monthsObj)
        const monthName = currentDate.toLocaleString('default', { month: 'short' }); // Get short month name
        const year = currentDate.getFullYear().toString().slice(-2); // Get last two digits of the year

        const result1 = `${monthName}-${year}`;
        this.selectedMonth = result1;
        this.monthsObject = monthsObj;

        const twentyFive = (monthProperties.length*25)/100
        const fifty = (monthProperties.length*50)/100
        const seventyFive = (monthProperties.length*75)/100
        const twentyFiveArray = monthProperties.slice(0,twentyFive);
        const fiftyArray = monthProperties.slice(0,fifty);
        const seventyFiveArray = monthProperties.slice(0,seventyFive);

        if(this.targetData.length <= twentyFive){
            this.selectedProcess = "twentyFive";
            this.percentage = 25;
        } else if(this.targetData.length <= fifty ){
          this.selectedProcess = "fifty"
          this.percentage = 50;
        } else if(this.targetData.length <= seventyFive) {
          this.selectedProcess = "seventyFive"
          this.percentage = 75;
        } else {
          this.selectedProcess = "hundred"
          this.percentage = 100;
        }


        this.fetchWeeksPIChartData(monthsObj, responses);
        responses.features.forEach((featureRes) => {
        
        let plannedArray = [];
        let progressPlannedArray = [];
        let achivedArray = [];
        let targetArray = []
        if (featureRes?.properties['Description'] !== 'nan') {
          let total = 0;
          let total1 = 0
          for (const property in featureRes.properties) {
            const findProperty = this.targetData.find((res) => res === property)
            if (findProperty && featureRes.properties[findProperty] !== 'nan') {
              plannedArray.push({
                key: findProperty, value: Number(featureRes.properties[findProperty])
              })
              total = total + Number(featureRes.properties[findProperty])
            }

            const findProperty1 = this.targetData.find((res) => res === property)
                if (findProperty1 && featureRes.properties[findProperty1] !== 'nan') {
                  total1 = total1 + Number(featureRes.properties[findProperty1])
                }
          }

          for (const property in featureRes.properties) {
            const findProperty = monthProperties.find((res) => res === property)
            if (findProperty && featureRes.properties[findProperty] !== 'nan') {
              progressPlannedArray.push({
                key: findProperty, value: Number(featureRes.properties[findProperty])
              })
            }

          }
          this.progressPlannedResult.push({ key: featureRes?.properties['Description'], value: Math.round(total), plannedArray: progressPlannedArray })
          this.targetArraytemp.push({ key: featureRes?.properties['Description'], value: Math.round(total1)})
          this.projectPlannedResult.push({ key: featureRes?.properties['Description'], value: Math.round(total), plannedArray: plannedArray })
        } else {
          // console.log(this.kmlData)
          let total = 0;
          for (const property in featureRes.properties) {
            const findProperty = monthProperties.find((res) => res === property)
            if (findProperty && featureRes.properties[findProperty] !== 'nan') {
              achivedArray.push({
                key: findProperty, value: Number(featureRes.properties[findProperty])
              })
              total = total + Number(featureRes.properties[findProperty])
            }
          }
          
          this.projectAchivedResult.push({ key: featureRes?.properties['Description'], value: Math.round(total), achivedArray: achivedArray })
        }
      })
   
        this.newProjectPlannedResult = this.progressPlannedResult.map((resp) => {
          console.log(resp , 'dsadsadsa')
          const floatTwentyFive = (Number.isFinite(twentyFive) && twentyFive % 1 !== 0) && resp.plannedArray.some((dataItem) => dataItem.key.includes(monthProperties[Math.round(twentyFive) - 1])) ? 0.5 : 0;
          const floatFifty = (Number.isFinite(fifty) && fifty % 1 !== 0) && resp.plannedArray.some((dataItem) => dataItem.key.includes(monthProperties[Math.round(fifty) - 1])) ? 0.5 : 0;
          const floatSeventyFive = (Number.isFinite(seventyFive) && seventyFive % 1 !== 0) && resp.plannedArray.some((dataItem) => dataItem.key.includes(monthProperties[Math.round(seventyFive) - 1])) ? 0.5 : 0;
          const twentyFiveCount = resp.plannedArray.length ? (((twentyFiveArray.filter(monthItem => resp.plannedArray.some(dataItem => dataItem.key.includes(monthItem))).length) + floatTwentyFive) / resp.plannedArray.length) * 100 : 0;
          const fiftyCount = resp.plannedArray.length ? (((fiftyArray.filter(monthItem => resp.plannedArray.some(dataItem => dataItem.key.includes(monthItem))).length) + floatFifty) / resp.plannedArray.length) * 100 : 0; 

          const seventyFiveCount = resp.plannedArray.length ? (((seventyFiveArray.filter(monthItem => resp.plannedArray.some(dataItem => dataItem.key.includes(monthItem))).length) + floatSeventyFive) / resp.plannedArray.length) * 100 : 0;
          const hundredCount = resp.plannedArray.length ? ((monthProperties.filter(monthItem => resp.plannedArray.some(dataItem => dataItem.key.includes(monthItem))).length) / resp.plannedArray.length) * 100 : 0;

          return {
            ...resp,
            twentyFive: twentyFiveCount.toFixed(2),
            fifty: fiftyCount.toFixed(2),
            seventyFive: seventyFiveCount.toFixed(2),
            hundred: hundredCount.toFixed(2)
          }
        })

        this.targetArraytemp.forEach((e,i) => {
          if(e.value > this.projectAchivedResult[i].value){
              this.projectAchivedResult[i].color = '#deeb34'
              this.projectAchivedResult[i].name = e.key
            }else if (e.value == this.projectAchivedResult[i].value){
              this.projectAchivedResult[i].color = '#34eb34'
              this.projectAchivedResult[i].name = e.key
            }
    
            
          })

          let percentage 
          if(this.selectedProcess === 'twentyFive') {
            percentage = 25
          }else if (this.selectedProcess === 'fifty'){
            percentage = 50
          }else if (this.selectedProcess === 'seventyFive'){
            percentage = 75
          }else if (this.selectedProcess === 'hundred'){
            percentage = 100
          }
        this.tempKmlData.forEach(async(element) => {
          if(element.name.includes('Achieved') ){
            await this.projectAchivedResult.forEach(e => {
             
              if(e.name == element.name.split('_')[1]){
                // e.value = ((element.measurement / this.plannedRoad)* percentage).toFixed(2) 
                e.value = element.measurement 
              }
            })
          }
        })

        this.newProjectAchivedResult = this.projectAchivedResult.map((resp, index) => {
          const floatTwentyFive = (Number.isFinite(twentyFive) && twentyFive % 1 !== 0) && resp.achivedArray.some((dataItem) => dataItem.key.includes(monthProperties[Math.round(twentyFive) - 1])) ? 0.5 : 0;
          const floatFifty = (Number.isFinite(fifty) && fifty % 1 !== 0) && resp.achivedArray.some((dataItem) => dataItem.key.includes(monthProperties[Math.round(fifty) - 1])) ? 0.5 : 0;
          const floatSeventyFive = (Number.isFinite(seventyFive) && seventyFive % 1 !== 0) && resp.achivedArray.some((dataItem) => dataItem.key.includes(monthProperties[Math.round(seventyFive) - 1])) ? 0.5 : 0;

          // const twentyFiveCount = (((twentyFiveArray.filter(monthItem => resp.achivedArray.some(dataItem => dataItem.key.includes(monthItem))).length) + floatTwentyFive) / this.progressPlannedResult[index].plannedArray.length) * 100;
          // const fiftyCount = (((fiftyArray.filter(monthItem => resp.achivedArray.some(dataItem => dataItem.key.includes(monthItem))).length) + floatFifty) / this.progressPlannedResult[index].plannedArray.length) * 100;
          // const seventyFiveCount = (((seventyFiveArray.filter(monthItem => resp.achivedArray.some(dataItem => dataItem.key.includes(monthItem))).length) + floatSeventyFive) / this.progressPlannedResult[index].plannedArray.length) * 100;
          // const hundredCount = ((monthProperties.filter(monthItem => resp.achivedArray.some(dataItem => dataItem.key.includes(monthItem))).length) / this.progressPlannedResult[index].plannedArray.length) * 100;
          const twentyFiveCount = ((resp.value / this.plannedRoad)* 25).toFixed(2) 
          const fiftyCount = ((resp.value / this.plannedRoad)* 50).toFixed(2)
          const seventyFiveCount = ((resp.value / this.plannedRoad)* 75).toFixed(2)
          const hundredCount = ((resp.value / this.plannedRoad)* 100).toFixed(2)

      

          return {
            ...resp,
            twentyFive: twentyFiveCount,
            fifty: fiftyCount,
            seventyFive: seventyFiveCount,
            hundred: hundredCount
          }
        })
     
        console.log(this.newProjectAchivedResult ,this.projectAchivedResult,)
      this.chartData(this.selectedProcess)
      this.projectCompletionweeks(weeksLeft , totalWeeks);
    }else{
      this.excelNotUploaded = true;
      this.snackbar.open('Currently excel is not uploaded in this project', 'Close', {
        duration: 3000,
        // panelClass: ['red-snackbar', 'custom-snackbar']
      });
      this.days.chartData=true;
      this.childEvent.emit(this.days)
      this.months = [];
      this.weeksChart = false;
      this.projectPlannedResult =[] 
        this.projectAchivedResult = []
    }

    if(this.inputData.selectedTableData && !this.excelNotUploaded){
      console.log(this.inputData.selectedTableData,'this.inputData.selectedTableData')
      const budgetData = [{
        name: "Budget Utilised", value: 0
      },
      {
        name: "Budget Transferred", value: 0
      },
      {
        name: "Budget Sanctioned", value: this.inputData.selectedTableData["Budget Sanctioned (in Lakhs)"] 
      },

      ]
      this.projectBudgetChart(budgetData)
    }
    })
  }

  fetchWeeksPIChartData(result, responses) {
    const monthlyTraget = [];
    const monthlyAchive = [];
    if (result[this.selectedMonth]?.length) {
      result[this.selectedMonth].forEach((month) => {
        let targetResult = [];
        let achiveResult = [];
        let description = '';
        responses.features.forEach((featureRes) => {

          if (featureRes.properties["Unnamed: 2"] === "Target" && featureRes.properties[month] !== 'nan') {
            description = featureRes.properties["Description"]
            targetResult.push({ name: description, value: featureRes.properties[month] })
          } else if (featureRes.properties["Unnamed: 2"] === "Achieve" && featureRes.properties[month] !== 'nan') {
            achiveResult.push({ name: description, value: featureRes.properties[month] })
          }
        })
        const part = month.split(' ')[0].match(/\d+/);
        while (monthlyTraget.length < part) {
          monthlyTraget.push(null);
        }
        monthlyTraget[part - 1] = { name: month, value: targetResult }
        monthlyAchive[part - 1] = { name: month, value: achiveResult }
      })
      this.monthlyAchive = monthlyAchive
      this.monthlyTraget = monthlyTraget
      // this.weeksPIChart(monthlyTraget);
    }
  }
  


  
  projectBudgetChart(data) {
    console.log(data,'datschart')
    // alert();
    // this.inputData.parentShowTable= false;
    // console.log()
    this.cdr.detectChanges();
    var chartDom = document.getElementById("project-budget-chart");
    console.log(chartDom,'--------------oop')
    var myChart = echarts.init(chartDom);
    const option = {
      title: {
        text: 'Project Budget ( In Lakhs)',
        left:'center',
        top:'5%',
        textStyle:{
          color:'white'
        }
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      label: {
        show: true,
        // position: 'top',
        color: "black",
        // fontSize:12,
      },
      legend: {
        top: '12%',
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      }, 
      xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01]
      },
      yAxis: {
        type: 'category',
        data: data.map(item => item.name),
        axisLabel: {
          color: 'white' 
        }
      },
      series: [
        {
          type: 'bar',
          data: data.map(item => item.value)
        },
      ]
    };
    myChart.setOption(option);
  }

  chartData(value){
    console.log(value,'value')
    console.log(this.projectAchivedResult ,this.newProjectPlannedResult , this.newProjectAchivedResult,'achievesss')
     this.cdr.detectChanges();
     var app: any = {};
     var chartDom = document.getElementById('chart-container-summary3');
    
 
     let achivedResult = [];
 
     this.newProjectAchivedResult.forEach((res,index)=>{
       // console.log(res ,index)
       // console.log(typeof(this.newProjectPlannedResult[index][value]) ,typeof(res['hundred']) , this.newProjectPlannedResult[index][value] > res['hundred'] )
       if(Number(this.newProjectPlannedResult[index][value]) > Number(res['hundred']) ){
         res[value] = res['hundred']
       }else{
         // alert(this.newProjectPlannedResult[index][value])
         res[value] = this.newProjectPlannedResult[index][value]
       }
       if(parseFloat(this.newProjectPlannedResult[index][value]) !== 0){
         achivedResult.push(res);
       }
     })
     
 
     console.log(achivedResult)
     const data = achivedResult.map(item => item.value)
     console.log("====================>>>>",data)
     var myChart = echarts.init(chartDom);
     const posList = [
       'left',
       'right',
       'top',
       'bottom',
       'inside',
       'insideTop',
       'insideLeft',
       'insideRight',
       'insideBottom',
       'insideTopLeft',
       'insideTopRight',
       'insideBottomLeft',
       'insideBottomRight'
     ];
     app.configParameters = {
       rotate: {
         min: -90,
         max: 90
       },
       align: {
         options: {
           left: 'left',
           center: 'center',
           right: 'right'
         }
       },
       verticalAlign: {
         options: {
           top: 'top',
           middle: 'middle',
           bottom: 'bottom'
         }
       },
       position: {
         options: posList.reduce(function (map, pos) {
           map[pos] = pos;
           return map;
         }, {})
       },
       distance: {
         min: 0,
         max: 100
       }
     };
     app.config = {
       rotate: 90,
       align: 'left',
       verticalAlign: 'middle',
       position: 'insideBottom',
       distance: 15,
       onChange: function () {
         const labelOption = {
           rotate: app.config.rotate,
           align: app.config.align,
           verticalAlign: app.config.verticalAlign,
           position: app.config.position,
           distance: app.config.distance
         };
         myChart.setOption({
           series: [
             {
               label: labelOption
             },
             {
               label: labelOption
             },
           ]
         });
       }
     };
     const labelOption = {
       show: true,
       position: app.config.position,
       distance: app.config.distance,
       align: app.config.align,
       verticalAlign: app.config.verticalAlign,
       rotate: app.config.rotate,
       formatter: '{c}  {name|{a}}',
       fontSize: 16,
       rich: {
         name: {}
       }
     };
     const option = {
       tooltip: {
         trigger: 'item',
         formatter: (params) => {
           const data = `${params.name}: ${params.value}%<br/>`
           return data;
         }
       },
       xAxis: [
         {
           axisLabel: {
             formatter: function (value) {
               return value.split(' ')[0];
             },
             textStyle: {
              color: 'White'
          }

           },
           name: 'Description of Items',
           nameLocation: 'middle',
           nameGap: 50,
           nameTextStyle: {
            color: 'White'
          },
           type: 'category',
           axisTick: { show: false },
           data: this.newProjectPlannedResult
           .filter(item => parseFloat(item[value]) !== 0)
           .map(item => item.key)
         }
       ],
       yAxis: [
        {
          type: 'value',
          name: 'Percent (%)',
          nameLocation: 'middle',
          nameGap: 100,
          axisLabel: {
            textStyle: {
              color: 'White'
            }
          }
        },
      ],
      
       series: [
         {
           name: 'Planned',
           type: 'bar',
           barGap: 0,
           label: {
             ...labelOption,
             formatter: (params) => {
               return params.value + "%  " + "Planned";
             },
           },
           emphasis: {
             focus: 'series'
           },
           data: this.newProjectPlannedResult.filter(item => parseFloat(item[value]) !== 0).map(item => item[value])
         },
         {
           name: 'Achived',
           type: 'bar',
           label: {
             ...labelOption,
             formatter: (params) => {
               return params.value + "%  " + "Achived";
             },
           },
           emphasis: {
             focus: 'series'
           },
           itemStyle: {
             color: (params)=>{
               const result = this.projectAchivedResult.find((res)=>res.name === params.name)
               return result?.color
             },
           },
           data: achivedResult.map(item => item[value])
         },
       ]
     };
     myChart.setOption(option);
     myChart.on('click', 'series', (params) => {   
       const projectIndex = this.newProjectPlannedResult.findIndex((resp) => resp.key === params.name)
       if (projectIndex) {
         this.innerChart = true;
        //  this.handleChartSeriesClick(projectIndex, params.name)
       }
     })
   }

   projectCompletionweeks(weeksLeft, totalWeeks) {
    // this.cdr.detectChanges();
    const customMaxValue = weeksLeft <= 100 ? 100 : weeksLeft;
    var chartDom = document.getElementById("weeks-chart-container");
    var myChart = echarts.init(chartDom);

    const gaugeData = [
      {
        value: 0,
        name: 'Weeks Left',
        title: {
          offsetCenter: ['-40%', '80%']
        },
        detail: {
          offsetCenter: ['-40%', '95%']
        }
      },
      {
        value: 0,
        name: 'Phase (%)',
        title: {
          offsetCenter: ['30%', '80%']
        },
        detail: {
          offsetCenter: ['30%', '95%']
        }
      }
    ];

    let count = 0
    const option = {
      series: [
        {
          type: 'gauge',
          radius: '70%',
          anchor: {
            show: true,
            showAbove: true,
            size: 18,
            itemStyle: {
              color: '#FAC858'
            }
          },
          pointer: {
            icon:
              'path://M2.9,0.7L2.9,0.7c1.4,0,2.6,1.2,2.6,2.6v115c0,1.4-1.2,2.6-2.6,2.6l0,0c-1.4,0-2.6-1.2-2.6-2.6V3.3C0.3,1.9,1.4,0.7,2.9,0.7z',
            width: 8,
            length: '80%',
            offsetCenter: [0, '8%']
          },

          progress: {
            show: true,
            overlap: true,
            roundCap: true
          },
          axisLine: {
            roundCap: true
          },
          data: gaugeData,
          title: {
            fontSize: 14
          },
          detail: {
            width: 40,
            height: 14,
            fontSize: 14,
            color: '#fff',
            backgroundColor: 'inherit',
            borderRadius: 3,
            formatter: function (value) {

              let result = count ? value + "%" : value + " / " + totalWeeks
              count++;
              return result
            }
          }
        }
      ]
    };



    gaugeData[0].value = weeksLeft > 0 ? weeksLeft : 0;
    gaugeData[1].value = this.percentage;
    myChart.setOption<echarts.EChartsOption>({
      series: [
        {
          data: gaugeData
        }
      ]
    });


    myChart.setOption(option);
  }

  


  ngAfterViewInit(){
    let obj = this;
    setTimeout(() => {
      // $('#chart-no-of-project').addClass('minimized');
      // $('.maxDiv').addClass('maximize');
      // $('.minimize').addClass('minimizeButton');

      // $('#chartExcel').addClass('minimized2');
      // $('.maxDiv2').addClass('maximize2');
      // $('.minimize2').addClass('minimizeButton2');

      $('#pwd-light').addClass('minimized6');
      $('.maxDiv6').addClass('maximize6');
      $('.minimize6').addClass('minimizeButton6');
      
      $('#project-running').addClass('minimized5');
      $('.maxDiv5').addClass('maximize5');
      $('.minimize5').addClass('minimizeButton5');

    //   $('.minimize').on('click', function(){minimize();});
    //   $('.maxDiv').on('click', function(){maximize();});
    
    // function minimize(){
    //   $('#chart-no-of-project').addClass('minimized');
    //   $('.maxDiv').addClass('maximize');
    //   $('.minimize').addClass('minimizeButton');
    // }
    
    // function maximize(){
    //   $('#chart-no-of-project').removeClass('minimized');
    //   $('.maxDiv').removeClass('maximize');
    //   $('.minimize').removeClass('minimizeButton');
    // }

      // $('.minimize2').on('click', function(){minimize2();});
      // $('.maxDiv2').on('click', function(){maximize2();});
    
    // function minimize2(){
    //   $('#chartExcel').addClass('minimized2');
    //   $('.maxDiv2').addClass('maximize2');
    //   $('.minimize2').addClass('minimizeButton2');
    // }
    
    // function maximize2(){
    //   $('#chartExcel').removeClass('minimized2');
    //   $('.maxDiv2').removeClass('maximize2');
    //   $('.minimize2').removeClass('minimizeButton2');
    // }

      $('.minimize5').on('click', function(){minimize5();});
      $('.maxDiv5').on('click', function(){maximize5();});
    
    function minimize5(){
      $('#project-running').addClass('minimized5');
      $('.maxDiv5').addClass('maximize5');
      $('.minimize5').addClass('minimizeButton5');
    }

    function maximize5(){
      $('#project-running').removeClass('minimized5');
      $('.maxDiv5').removeClass('maximize5');
      $('.minimize5').removeClass('minimizeButton5');
    }

      $('.minimize6').on('click', function(){minimize6();});
      $('.maxDiv6').on('click', function(){maximize6();});
    
    function minimize6(){
      $('#pwd-light').addClass('minimized6');
      $('.maxDiv6').addClass('maximize6');
      $('.minimize6').addClass('minimizeButton6');
    }
    
    function maximize6(){
      $('#pwd-light').removeClass('minimized6');
      $('.maxDiv6').removeClass('maximize6');
      $('.minimize6').removeClass('minimizeButton6');
    }
    

    

  }, 1000);
  }

  
  saveData(result: string) {
    this.savedItems.push(result);
    // console.log(result,'resulttt')
  }

  createSiteCountChart(){
    // console.log(this.sites)
   
    let data = []
    let name = []
    this.showNoProject = true;
    this.temp = []
    let a =[]
    this.sites.forEach((res) => {
      if(localStorage.getItem('email') === 'osnagarnigam@rediffmail.com' || localStorage.getItem('email') === 'vishvaraj@heliware.co.in'){
        this.mapService.getProjects(res.id)
        .pipe(takeUntil(this.unsubscribeSubject))
        .subscribe((res1) => {
          
        // a.push(...res1)
          this.Counts = this.Counts+res1.length
          res1.forEach(async (w:any)=>{
            await this.temp.push(w)
          })
          name.push(res.name)
          data.push(res1.length)
          // console.log(data,name,'name')
          this.callChart(data,name)
          // this.filterTableData(temp)
        })
        

      }else{
        this.mapService.fetchAssignedProjects
        ({userid:localStorage.getItem('id'),siteid:res.id})
        // .pipe(takeUntil(this.unsubscribeSubject))
        .subscribe((resp:any)=>{
          console.log(resp,"resp======>")
          // a.push(...resp)
          this.Counts = this.Counts+resp.length
          resp.forEach(async (w:any)=>{
            await this.temp.push(w)
          }) 
          // console.log(temp)
          name.push(res.name)
          data.push(resp.length)
          // console.log(data,name,'name')
          this.callChart(data,name);
          // this.filterTableData(a);
          // this.filterTableData(temp)
        })
      }
      // console.log(res)
    })
    console.log(this.temp)
    // this.assignedProjects = temp
    // console.log(this.assignedProjects)
   
    setTimeout(() => {
      this.getProjectOverviewData(); 
      this.getPlannedLength()
      this.getCompletedLength() 
    }, 3000);
    
  }
  


  test3(res1 ,iconUrl ,cat ){
    let GeoJSONData:any
    res1.forEach((ele:any )=> {
      // console.log("ele",ele);
      let latitude = Number(ele.maps.metaData?.lat);
      let longitude = Number(ele.maps.metaData?.lng);

        GeoJSONData = {
        "type": "FeatureCollection",
        "features": [
          {
            "type": "Feature",
            "properties": {
              title: ele.item,
              data: ele,
              // cat: cat
            },
            "geometry": {
              "coordinates": [
                    longitude,
                    latitude,
              ],
              "type": "Point"
            }
          }
        ]
      };
      // console.log(GeoJSONData)
      this.map.data.addGeoJson(GeoJSONData);
      // const site = this.sites.find((site)=>site.id === res.id)
         
      // this.map.data.forEach(feature => {
      //   console.log(feature)
        
      // })
    //   if (res1.category === "PWD") {
    //      iconUrl = {url:'assets/icon/pwd.png',scaledSize: new google.maps.Size(80, 80)};
    //           } else if (res1.category === "Light") {
    //      iconUrl = {url:'assets/icon/light.png',scaledSize: new google.maps.Size(50, 30)};
    //           } else if (res1.category === "Light/Bridge Corporation"){
    //      iconUrl = {url:'assets/icon/light_bridge.png', scaledSize: new google.maps.Size(80, 80)}
    //           } else if (res1.category === "Jal Kal Vibhag"){
    //      iconUrl = { url:'assets/icon/jal_kal_vibhag.png', scaledSize: new google.maps.Size(50, 50)}
    //           } else if (res1.category === "STP") {
    //      iconUrl = { url:'assets/icon/stp.png', scaledSize: new google.maps.Size(30, 30)}
    //           }
    //            if(iconUrl){
    
    // this.map.data.setStyle(function (feature) {
    //   // console.log(feature)
    //   return {
    //     strokeWeight: 3,
    //     strokeColor: 'green',
    //     icon: iconUrl,
    //   };
    // });
    // } else {
    //   this.map.data.setStyle({
    //     strokeWeight: 3,
    //     strokeColor: 'green',
    //   });
    // }   
      // this.map.panTo(new google.maps.LatLng(latitude,longitudeP));
      // bounds.extend(new google.maps.LatLng(latitude,longitude));
    })
  }
  addGeojsonToMap(url,id){
    // alert("hello")
    console.log(url,"url")
    let obj = this
    this.map.data.forEach(function(feature:any) {
      console.log(feature ,"feature")
      if(feature['Fg'].title.includes('urlgeojson') && feature['Fg'].title !== 'urlgeojson'+url[0].measurement ){
        console.log(feature)
        obj.map.data.remove(feature);
      } 
    });
   

    this.map.data.loadGeoJson(url[0].url, {},
      function(features) {
        console.log(features,"features")
        obj.map.data.forEach(function(feature:any) {
          console.log(feature,"fearure")
          if(feature.getProperty('title') == undefined){
            feature.setProperty('title','urlgeojson'+url[0].measurement );
          }
        }); 
      })
  }

  fetchSiteData() {
    // if (this.organization === 'ADMIN') {
      this.sitesService.fetchSitesData(this.creatorid).
        pipe(takeUntil(this.unsubscribeSubject)).subscribe((res) => {
           this.sites = res  
        //  console.log(this.sites,'this.sites')
         this.createSiteCountChart()
       })
    // }
  //  else{
  //     this.sitesService.fetchSitesData(this.creatorid).
  //       pipe(takeUntil(this.unsubscribeSubject)).subscribe((res) => {
  //         console.log("res",res)
  //          this.sites = res  
  //        console.log(this.sites,'this.sites')
  //        this.createSiteCountChart()
  //      })
  //   }
  }
//   


  fetchAssignedData(){
      // console.log(localStorage.getItem('id'),'ele')
      this.mapService.getAssignFetchSites({userid:localStorage.getItem('id')}).subscribe((resp:any) => {
           this.sites = resp.data
           this.createSiteCountChart()
           console.log(this.sites,"this.sites")
          })
  }

  getProjectData(e) {
    console.log("as",this.isNotVisible);

    $('#pwd-light').removeClass('minimized6');
    $('.maxDiv6').removeClass('maximize6');
    $('.minimize6').removeClass('minimizeButton6');

    setTimeout(() => {
      
      $('#chart-drag-summery').addClass('minimized7') ;
      $('.maxDiv7').addClass('maximize7');
      $('.minimize7').addClass('minimizeButton7');
  
    
      $('.minimize3').on('click', function(){minimize3();});
      $('.maxDiv3').on('click', function(){maximize3();});
    
    function minimize3(){
      $('#chart-container-status-summary').addClass('minimized3');
      $('.maxDiv3').addClass('maximize3');
      $('.minimize3').addClass('minimizeButton3');
    }
    
    function maximize3(){
      $('#chart-container-status-summary').removeClass('minimized3');
      $('.maxDiv3').removeClass('maximize3');
      $('.minimize3').removeClass('minimizeButton3');
    }

      $('.minimize4').on('click', function(){minimize4();});
      $('.maxDiv4').on('click', function(){maximize4();});
    
    function minimize4(){
      $('#chart-container-status-summary1').addClass('minimized4');
      $('.maxDiv4').addClass('maximize4');
      $('.minimize4').addClass('minimizeButton4');
    }
    
    function maximize4(){
      $('#chart-container-status-summary1').removeClass('minimized4');
      $('.maxDiv4').removeClass('maximize4');
      $('.minimize4').removeClass('minimizeButton4');
    }

      $('.minimize7').on('click', function(){minimize7();});
      $('.maxDiv7').on('click', function(){maximize7();});
    
    function minimize7(){
      $('#chart-drag-summery').addClass('minimized7');
      $('.maxDiv7').addClass('maximize7');
      $('.minimize7').addClass('minimizeButton7');
    }
    
    function maximize7(){
      $('#chart-drag-summery').removeClass('minimized7');
      $('.maxDiv7').removeClass('maximize7');
      $('.minimize7').removeClass('minimizeButton7');
    }

 
  
    }, 2000);
    console.log(this.showProjectTable,'shownoproject')

    this.completion= true
    this.showButton = false;
    this.hideButten = true;
    console.log(this.hideButten)
    this.showProjectTable =true

    this.showNoProject = true;
    this.isVisible = false;
    this.isNotVisible=true;
    this.chartpwd= true;
    this.selectedSite = e.value;

    this.showProjectChart = false;
    this.weeksChart = false;
    this.selectedTableData =false;
    this.tableData =this.overAllTabledata 
  

    
    if (this.selectedSite.name === 'Prayagraj - PWD') {
      this.showBridge=false;
      this.showPWD = true;
      this.showLight = false;
      this.showProject =false;
      this.showBudget =true;
      this.chart=true;
      this.showStyle= true;
      this.tableData = this.tableData.filter((item) => {
        return item.Department === 'PWD';
      });    
    }
     else if (this.selectedSite.name === 'Prayagraj - Light') {
      this.showBridge=false;
      this.showPWD = false;
      this.showLight = true;
      this.showProject =false;
      this.showBudget =true;
      this.chart=true;
      this.showStyle= true;
      this.tableData = this.tableData.filter((item) => {
        return item.Department === 'Light';
      });    
    }
    else if (this.selectedSite.name === 'Prayagraj - Light/Bridge Corporation') {
      this.showBridge=true;
      this.showPWD = false;
      this.showLight = false;
      this.showProject =false;
      this.showBudget =true;
      this.chart=true;
      this.showStyle= true;
      this.tableData = this.tableData.filter((item) => {
        return item.Department === 'Light/Bridge Corporation';
      });
    }
   
    else{
      this.showBridge=false;
      this.showPWD = false;
      this.showLight = false;
      this.showProject =false;
      this.showBudget =false;
      this.chart=true;
    }
    
    this.showCompletionChart = true;
    this.loading = true;
    // this.showNoProject = false;
    this.form.get('selectedProject').setValue('');
    this.form.get('selectedProject').enable();

    // console.log(this.form.controls.selectedSite.value)
    this.siteId = this.form.controls.selectedSite.value;
    // console.log(e.value.name,this.form.controls.selectedProject.value);
    // this.showChart = true
    this.showRunningStatus = true
    this.siteId = this.form.controls.selectedSite.value.id
    // this.panToOnMap(this.form.controls.selectedSite.value, 'site');
    let temp = '';
    if (localStorage.getItem('email') === 'osnagarnigam@rediffmail.com' || localStorage.getItem('email') === 'vishvaraj@heliware.co.in') {
      this.mapService.getProjects(this.siteId)
        .pipe(takeUntil(this.unsubscribeSubject))
        .subscribe((res) => {
          this.projects = res;
          // this.showProjectCompletion()
          let obj = {}
          res.forEach(element => {
            if (obj.hasOwnProperty(element['category'])){
              obj[element['category']].push(element)
            }else{
              obj[element['category']] = [element]
            }
          })
          this.chartShowStatus(obj)
          this.chartShowStatusPie(obj) 
          this.showProjectCompletion()
          // chartShowStatus(temp)
          this.loading = false
          // this.test().subscribe(() => {
  
          // })
          this.showProjectsOnMap(this.projects);
          // console.log(this.projects,"projects")
          // this.showProjectTable = false;
        }, (err)=>{
          this.loading = false;
  
        }
        );
        // console.log(this.parentData) 
   
    } 
    else {
      this.mapService.fetchAssignedProjects({userid:localStorage.getItem('id'), siteid: this.siteId}).subscribe((res:any) =>{
        this.projects = res;
        console.log(this.projects,"this.projects==>")
        let obj = {}
        res.forEach(element => {

          if (obj.hasOwnProperty(element['category'])){
            obj[element['category']].push(element)
          }else{
            obj[element['category']] = [element]
          }
          // this.mapService.assignedProjects(this.siteId).pipe(takeUntil(this.unsubscribeSubject)).subscribe((resp) =>{
          //   this.projects =resp;
          //   console.log(this.projects,'this.projects')
          // })
        })
        this.chartShowStatus(obj)
        this.chartShowStatusPie(obj) 
        this.loading = false
        this.showProjectCompletion()
        // this.test().subscribe(() => {
          
        // })
        this.showProjectsOnMap(this.projects);
        // console.log(this.projects,'===================>>>>project')
        // this.showProjectTable = false;
      }, (err)=>{
        this.loading = false;
      })
      

    }
  }

  showProjectCompletion(){
   this.showCompletionChart =true;
    this.loading = true;
    this.showRunningStatus = true;
    this.showChart = true
    let temp = [];
    let name = [];
    this.projects.forEach(e => {
      temp.push({ projectId: e.id, siteId: this.siteId });
      name.push(e.item);
    });
    this.mileStoneArray = [];
    this.achivedArray = [];
    this.test().subscribe(() => {
      console.log("Organization value:", this.organization);
        console.log("ADMIN detected, executing tempChart");
        this.tempChart();
    });
    this.loading = false;
  }
 
  childData(value) {
    this.excelNotFound = value.chartData;
    if (value) {
      this.totalProjectDays =
      value.projectStartDate !== "NaT" &&
        value.projectEndDate !== "NaT"
          ? Math.abs(
            (new Date( value.projectEndDate) as any) -
            (new Date(value.projectStartDate ) as any)
          ) / (1000 * 60 * 60 * 24)
          : 0;
    }
    console.log(this.totalProjectDays,'totalProjectDays')
  }
  

  getProjectChart() {
    
    setTimeout(() => {
      $('#progress_drag_chart').addClass('minimized10');
      $('.maxDiv10').addClass('maximize10');
      $('.minimize10').addClass('minimizeButton10');
      
      $('.minimize8').on('click', function(){minimize8();});
      $('.maxDiv8').on('click', function(){maximize8();});
    
    function minimize8(){
      $('#project-budget-chart').addClass('minimized8');
      $('.maxDiv8').addClass('maximize8');
      $('.minimize8').addClass('minimizeButton8');
    }
    
    function maximize8(){
      $('#project-budget-chart').removeClass('minimized8');
      $('.maxDiv8').removeClass('maximize8');
      $('.minimize8').removeClass('minimizeButton8');
    }

      $('.minimize9').on('click', function(){minimize9();});
      $('.maxDiv9').on('click', function(){maximize9();});
    
    function minimize9(){
      $('#project-detail').addClass('minimized9');
      $('.maxDiv9').addClass('maximize9');
      $('.minimize9').addClass('minimizeButton9');
    }
    
    function maximize9(){
      $('#project-detail').removeClass('minimized9');
      $('.maxDiv9').removeClass('maximize9');
      $('.minimize9').removeClass('minimizeButton9');
    }

      $('.minimize10').on('click', function(){minimize10();});
      $('.maxDiv10').on('click', function(){maximize10();});
    
    function minimize10(){
      $('#progress_drag_chart').addClass('minimized10');
      $('.maxDiv10').addClass('maximize10');
      $('.minimize10').addClass('minimizeButton10');
    }
    
    function maximize10(){
      $('#progress_drag_chart').removeClass('minimized10');
      $('.maxDiv10').removeClass('maximize10');
      $('.minimize10').removeClass('minimizeButton10');
    }

      $('.minimize11').on('click', function(){minimize11();});
      $('.maxDiv11').on('click', function(){maximize11();});
    
    function minimize11(){
      $('#project_timeline_drag').addClass('minimized11');
      $('.maxDiv11').addClass('maximize11');
      $('.minimize11').addClass('minimizeButton11');
    }
    
    function maximize11(){
      $('#project_timeline_drag').removeClass('minimized11');
      $('.maxDiv11').removeClass('maximize11');
      $('.minimize11').removeClass('minimizeButton11');
    }
    }, 2000);

    
    this.pwdCharts=false
    this.showProjectChart =true;
    this.showPWD= false;
    this.showLight=false;
    this.isNotVisible=false;
    this.completion= false;
    this.completion1= true;
    this.completion2= true;
    this.completion3= true;
    this.completion4= false;
    this.completion5= true;
    this.tables= true;
   
    
 console.log(this.form.controls.selectedProject.value,"==========,>>")
 console.log(this.projects,'dsdsad')
    this.selectProject = this.projects.find((res)=> res.item === this.form.controls.selectedProject.value)

    console.log(this.selectProject,'this.selectProject')
    this.hideButten = false;
    this.showButton = true;

    if(this.selectProject){
    this.pwdCharts=false
    this.excelNotFound = false;
    this.categoryChart = false
    this.showProjectTable= false;
    this.showNoProject =false;
    this.showProjectChart =true;
    this.showChart =false;
    this.showRunningStatus = false

    this.panToOnMap(this.selectProject, 'projects');

    const selectedProject = this.form.controls.selectedProject.value.split(',');

    this.selectedTableData = this.tableData.find((res)=> Number(res["Sl. No."]) === Number(selectedProject[0]))

    // this.showProjectsOnMap(this.projects)
    this.projectName = selectedProject[1]
    if(this.organization === "ADMIN"){
      this.inputData = {
        projectId: this.selectProject.id,
        siteId: this.form.controls.selectedSite.value.id,
        selectedTableData: this.selectedTableData
      }
    }else{
      this.inputData = {
        projectId: this.selectProject._id,
        siteId: this.form.controls.selectedSite.value.id,
        selectedTableData: this.selectedTableData
      }
    }
    
    console.log(this.inputData,"this.selectedTableData")
    console.log(this.creatorid)
    this.sitesService.fetchSitesData(this.creatorid)
  .pipe(
    tap((res)=> { 
      console.log(res)
      this.siteId = res[0].id
    }),
    switchMap(()=>{
      return this.mapService.getProjects(this.siteId) 
    }),
  )
  .subscribe((res)=> {
    console.log(this.projects,"projects==>")
    this.userId = this.projects.find((project) => project?.maps?.excelData?.userId === this.creator.email)
    console.log(this.userId)
    let selectedProject = this.projects.find((e)=> e.id == this.inputData['projectId'])
    this.addGeojsonToMap(selectedProject['kmlData'],selectedProject['id']);
  });
    }
    this.ngOnChanges();
  }

  onToggleClose() {
    this.closeSideNav.emit();
  }

  // onDropdownChange() {
  //   const selectedValue = this.myForm.get('selectedOption').value;
  //   console.log('Selected value:', selectedValue);
  //   // Add your logic or method call here based on the selected value
  // }

  ngOnDestroy(): void {
    if (this.myChart) {
      clearInterval(this.interval);
      this.myChart.dispose();
  }
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }

  ////setting map instance
  mapSetup() {
    this.map = new google.maps.Map(this.gMapElement.nativeElement, {
      center: new google.maps.LatLng(28.4601, 77.02635),
      zoom: 18,
      tilt: 45,
      styles: this.darkModeStyle,
      rotateControl: true,
      rotateControlOptions: { 
        position: google.maps.ControlPosition.LEFT_CENTER,
      },
      fullscreenControl: false,
      scaleControl: true,
      disableDefaultUI: true,
      gestureHandling: 'greedy'
    })
    this.mapService.init(this.map, this.map1, this.mappanel1,this.mappanel2, this.mappanel3, this.mappanel4,this.mappanel5, this.loading);
    this.data = new google.maps.Data({
      featureFactory: (geometry) => {
        const feature = new google.maps.Data.Feature({ geometry, id: uuid.v4() });
        return feature;
      }
    });
      //----------------add new route----  
      let temp=""
      if (localStorage.getItem('email') === 'osnagarnigam@rediffmail.com' || localStorage.getItem('email') === 'vishvaraj@heliware.co.in') {
        temp = 'Pmis';
        this.sitesService.fetchSitesData(this.creatorid).subscribe(res => {
          let sitesData = res;
          // console.log(this.creatorid,sitesData,'sites')
          let GeoJSONData:any;
  
          var bounds = new google.maps.LatLngBounds();
          sitesData.forEach(ele => {
            // console.log(ele)
              GeoJSONData = {
              "type": "FeatureCollection",
              "features": [
                {
                  "type": "Feature",
                  "properties": {
                    title: ele.name,
                  },
                  "geometry": {
                    "coordinates": [
                      ele?.longitude,
                      ele?.latitude,
                          
                    ],
                    "type": "Point"
                  }
                }
              ]
            };
         this.map.data.addGeoJson(GeoJSONData);
         this.map.panTo(new google.maps.LatLng(ele?.latitude,ele?.longitude));
         bounds.extend(new google.maps.LatLng(ele?.latitude,ele?.longitude));
          })
  
          this.map.fitBounds(bounds);
          var infowindow = new google.maps.InfoWindow({
            pixelOffset: new google.maps.Size(0, -40) // offset for icon
          });
      
          let obj = this
          this.map.data.addListener('mouseover', function(evt) {
            infowindow.setContent(`${evt.feature.Fg.title}`);
            infowindow.setPosition(evt.feature.getGeometry().get());
            infowindow.open(obj.map);
          });
          this.map.data.setStyle(function(feature) {
            return {
            strokeWeight: 3,
             strokeColor: 'yellow',
           }
         });
     })
      } 
      else {
        this.mapService.getAssignFetchSites({userid:localStorage.getItem('id')}).subscribe((resp:any) => {
          // this.sites = resp.data
          
          let sitesData = resp.data;
          // console.log(this.creatorid,sitesData,'sites')
          let GeoJSONData:any;
  
          var bounds = new google.maps.LatLngBounds();
          sitesData.forEach(ele => {
            // console.log(ele)
              GeoJSONData = {
              "type": "FeatureCollection",
              "features": [
                {
                  "type": "Feature",
                  "properties": {
                    title: ele.name,
                  },
                  "geometry": {
                    "coordinates": [
                      ele?.longitude,
                      ele?.latitude,
                          
                    ],
                    "type": "Point"
                  }
                }
              ]
            };
         this.map.data.addGeoJson(GeoJSONData);
         this.map.panTo(new google.maps.LatLng(ele?.latitude,ele?.longitude));
         bounds.extend(new google.maps.LatLng(ele?.latitude,ele?.longitude));
          })
  
          this.map.fitBounds(bounds);
          var infowindow = new google.maps.InfoWindow({
            pixelOffset: new google.maps.Size(0, -40) // offset for icon
          });
      
          let obj = this
          this.map.data.addListener('mouseover', function(evt) {
            infowindow.setContent(`${evt.feature.Fg.title}`);
            infowindow.setPosition(evt.feature.getGeometry().get());
            infowindow.open(obj.map);
          });
          this.map.data.setStyle(function(feature) {
            return {
            strokeWeight: 3,
             strokeColor: 'yellow',
           }
         });
     })


      }
     //----------------end of add new route----
  }
  

   ///////////////////////////////////location///////////////////////////////////
   selectedPlace(i: number) {
    const place_id = this.resultPlace[i].raw.place_id;
    this.placeHint = this.resultPlace[i].label;
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        const response = JSON.parse(xhttp.responseText);
        if (response && response.geometry) {
          // Extract type and coordinates
          const geometry = response.geometry;
          const names = response.names || {}; // Handle case if names is not present

          // Pass type, coordinates, and names
          this.searchResult({
            type: geometry.type,
            coordinates: geometry.coordinates
          }, names);
          
          this.resultPlace = [];
        }
      }
    };
    
    xhttp.open(
      "GET",
      `https://nominatim.openstreetmap.org/details.php?place_id=${place_id}&addressdetails=1&hierarchy=0&group_hierarchy=1&polygon_geojson=1&format=json`,
      true
    );
    xhttp.send();
}

searchResult(geometry: any, names: any) {
  const data = {
      type: geometry.type,
      coordinates: geometry.coordinates,
      names: names.name || {} 
  };
  this.mapService.searchOnMapData.next(data);
}

  
  placesearch(){
    if (this.placeHint.length > 2) {
      setTimeout(() => {
        this.placeSearch.routeStart(this.placeHint).then((data) => {
          this.resultPlace = data;
        });
      }, 1500);
    }
  }

  navigateToHome() {
    this.router.navigateByUrl('/', {skipLocationChange:true}).then(()=>{
      this.router.navigate(['/new-dashboard']);
    })
   
  }
  onLogout(){
    this.auth.saveToken(null)
    this.router.navigate(['/'])
  }
  
  panToOnMap(data, type){
    // console.log("data",data);
    if(type == 'site'){
      // console.log("lat,long",data?.latitude, data?.longitude);
      this.map.panTo(new google.maps.LatLng(data?.latitude,data?.longitude));
      // this.map.setZoom(15)
    }
    else if(type == 'projects'){
      let latitude = Number(data?.maps.metaData?.lat);
      let longitude = Number(data?.maps.metaData?.lng);
      // console.log("lat,long",latitude, longitude);
      
      this.map.panTo(new google.maps.LatLng( latitude,longitude));
      this.map.setZoom(16.7)
    }
  }

  dateDiffInDays(currentDate: Date, updatedAtDate: Date): number {
    const diffInMilliseconds = currentDate.getTime() - updatedAtDate.getTime();
    return Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
  }

  showProjectsOnMap(data){
    // console.log("data",data);
    
    let sitesData = data;
    let GeoJSONData:any;

    let this2 = this;
    this.map.data.forEach(function(feature) {
      // console.log(this.map.data,'this.map.data')
      // filter...
      
      this2.map.data.remove(feature);
  });




    // this.mapFeatures.forEach(ele => {
    //   console.log("ele",ele);
    //   // Setup event handler to remove GeoJSON features
    //     for (var i = 0; i < ele.length; i++){
    //      this.map.data.remove(ele[i]); 
    //     }
    // });
        // this.map.data.setMap(null);

    var bounds = new google.maps.LatLngBounds(); //for extend/fit slected projects on map default
    sitesData.forEach(ele => {
      let latitude = Number(ele.maps.metaData?.lat);
      let longitude = Number(ele.maps.metaData?.lng);

      
      const currentDate = new Date();
      const updatedAtDate = new Date(ele.updatedAt);

      const diffInDays = this.dateDiffInDays(currentDate, updatedAtDate);
      let t 
      if(diffInDays == 0){
        t = `${ele.item}<br>last-updated: Just Today`
      }else{
        t = `${ele.item}<br>last-updated: ${diffInDays} days ago`
      }
        GeoJSONData = {
        "type": "FeatureCollection",
        "features": [
          {
            "type": "Feature",
            "properties": {
              title: t,
              data: ele
            },
            "geometry": {
              "coordinates": [
                    longitude,
                    latitude,
              ],
              "type": "Point"
            }
          }
        ]
      };
      // console.log(GeoJSONData)
   this.map.data.addGeoJson(GeoJSONData);

   this.map.panTo(new google.maps.LatLng(latitude,longitude));
   bounds.extend(new google.maps.LatLng(latitude,longitude));
    })

    this.map.fitBounds(bounds);
    var infowindow = new google.maps.InfoWindow({
      pixelOffset: new google.maps.Size(0, -40) // offset for icon
    });

    let obj = this
    // this.map.data.addListener('mouseover', function(evt) {
    //   // console.log(evt.feature.h)
    //   infowindow.setContent(`${evt.feature.h.title}`);
    //   infowindow.setPosition(evt.feature.getGeometry().get());
    //   infowindow.open(obj.map);
    // });

    // this.map.data.addListener('click', function(evt) {
    //   // console.log(evt.feature.h.data)
    //   obj.form.controls.selectedProject.value = evt.feature.h.data
    //   obj.getProjectChart()
    // });

    // clickIcon 
    this.map.data.addListener('click', (evt: any) => {
      console.log(evt.feature,"===>")
      if (evt.feature.Fg.data.category === "Light") {
        const selectedSiteValue = this.sites.find(site => site.name === 'Prayagraj - Light');
        if (selectedSiteValue) {
          this.form.get('selectedSite').setValue(selectedSiteValue);
          console.log("selectedSite value:", selectedSiteValue);
          this.getProjectData({ value: selectedSiteValue });
        }
        this.map.data.addListener('click', (evt) => {
          console.log("evt",evt)
          const  SelectProject = obj.form.controls.selectedProject.value = evt.feature.Fg.data.item;
          if(SelectProject){
            this.form.get('selectedProject').setValue(SelectProject);
            obj.getProjectChart()
          }
          
         
          
        });      
      } 
      else if(evt.feature.Fg.data.category === "PWD"){
        const selectedSiteValue = this.sites.find(site => site.name === 'Prayagraj - PWD');
        if (selectedSiteValue) {
          this.form.get('selectedSite').setValue(selectedSiteValue);
          console.log("selectedSite value:", selectedSiteValue);
          this.getProjectData({ value: selectedSiteValue });
        }
        this.map.data.addListener('click', (evt) => {
          console.log("evt",evt)
          const  SelectProject = obj.form.controls.selectedProject.value = evt.feature.Fg.data.item;
          if(SelectProject){
            this.form.get('selectedProject').setValue(SelectProject);
            obj.getProjectChart()
          }   
        });
      }
    });


    this.map.data.addListener( "dblclick", function (evt) { 
      obj.router.navigate(['/gis/'+evt.feature.Fg.data.id],{ state: { isTrue: true } })
   });
    // if(iconUrl){
    
    this.map.data.setStyle((feature:any) => {
    console.log(feature,"feature====>")
      const site = this.sites.find((site)=>site.id === this.siteId)
      let iconUrl : {
        url:string,
        scaledSize:any
      };
      if (site?.category === "PWD" || feature.Fg.data.category === "PWD") {
        iconUrl = {url:'assets/icon/pwd.png',scaledSize: new google.maps.Size(80, 80)};
      }
       else if (site?.category === "Light" || feature.Fg.data.category === "Light") {
        iconUrl = {url:'assets/icon/light.png',scaledSize: new google.maps.Size(50, 30)};
      }
       else if (site?.category === "Light/Bridge Corporation" || feature.Fg.data.category === "Light/Bridge Corporation" ){
        iconUrl = {url:'assets/icon/light_bridge.png', scaledSize: new google.maps.Size(80, 80)}
      }
       else if (site?.category === "Jal Kal Vibhag" ||  feature.Fg.data.category === "Jal Kal Vibhag" ){
        iconUrl = { url:'assets/icon/jal_kal_vibhag.png', scaledSize: new google.maps.Size(50, 50)}
      }
       else if (site?.category === "STP" ||  feature.Fg.data.category === "STP") {
        iconUrl = { url:'assets/icon/stp.png', scaledSize: new google.maps.Size(30, 30)}
      }
      return {
        strokeWeight: 3,
        strokeColor: 'green',
        icon: iconUrl,
      };
    });


    // this.map.data.setStyle((feature: any) => {
    //   console.log(feature, "feature====>");
    //   const site = this.sites.find((site) => site.id === this.siteId);

    //   let iconUrl: {
    //     url: string,
    //     scaledSize: any
    //   };
    
    //   if (site?.category === "PWD" || feature.Fg.data.category === "PWD") {
    //     iconUrl = { url: 'assets/icon/pwd.png', scaledSize: new google.maps.Size(80, 80) };
    //   } else if (site?.category === "Light" || feature.Fg.data.category === "Light") {
    //     iconUrl = { url: 'assets/icon/light.png', scaledSize: new google.maps.Size(50, 30) };
    //   } else if (site?.category === "Light/Bridge Corporation" || feature.Fg.data.category === "Light/Bridge Corporation") {
    //     iconUrl = { url: 'assets/icon/light_bridge.png', scaledSize: new google.maps.Size(80, 80) };
    //   } else if (site?.category === "Jal Kal Vibhag" || feature.Fg.data.category === "Jal Kal Vibhag") {
    //     iconUrl = { url: 'assets/icon/jal_kal_vibhag.png', scaledSize: new google.maps.Size(50, 50) };
    //   } else if (site?.category === "STP" || feature.Fg.data.category === "STP") {
    //     iconUrl = { url: 'assets/icon/stp.png', scaledSize: new google.maps.Size(30, 30) };
    //   }
    //   return {
    //     strokeWeight: 3,
    //     strokeColor: 'green',
    //     icon: iconUrl,
    //   };
    // });

// icon 
    this.map.data.setStyle((feature: any) => {
      console.log(feature.Fg.data.categoryIcon,"categoryIcon")
      const site = this.sites.find((site) => site.id === this.siteId);
      let iconUrl: {
        url: string,
        scaledSize: any
      };
    
      if (site?.category === "PWD" || feature.Fg.data.category === "PWD") {
        iconUrl = { url: 'assets/icon/pwd.png', scaledSize: new google.maps.Size(80, 80) };
         if(feature.Fg.data.category === "Repairing of Road"){
          iconUrl = { url:feature.Fg.data.categoryIcon, scaledSize: new google.maps.Size(40, 40) };
         }
        else if(feature.Fg.data.category === "Construction"){
          iconUrl = { url:feature.Fg.data.categoryIcon, scaledSize: new google.maps.Size(40, 40) };
         }
         else if(feature.Fg.data.category === "Widening_Strengthening"){
          iconUrl = { url:feature.Fg.data.categoryIcon, scaledSize: new google.maps.Size(40, 40) };
         }

      }else if (site?.category === "Light" || feature.Fg.data.category === "Light") {
        iconUrl = { url: 'assets/icon/light.png', scaledSize: new google.maps.Size(50, 30) };
      } else if (site?.category === "Light/Bridge Corporation" || feature.Fg.data.category === "Light/Bridge Corporation") {
        iconUrl = { url: 'assets/icon/light_bridge.png', scaledSize: new google.maps.Size(80, 80) };
      } else if (site?.category === "Jal Kal Vibhag" || feature.Fg.data.category === "Jal Kal Vibhag") {
        iconUrl = { url: 'assets/icon/jal_kal_vibhag.png', scaledSize: new google.maps.Size(50, 50) };
      } else if (site?.category === "STP" || feature.Fg.data.category === "STP") {
        iconUrl = { url: 'assets/icon/stp.png', scaledSize: new google.maps.Size(30, 30) };
      }
      if(feature.Fg.data != undefined){
        // const tooltip = feature.Fg.title; 
      }
      return {
        strokeWeight: 3,
        strokeColor: 'yellow',
        icon: iconUrl,
        // title: feature.Fg.title 
      };
    });
   
   
    
// end icon 
   
    
    // } else {
    //   this.map.data.setStyle({
    //     strokeWeight: 3,
    //     strokeColor: 'green',
    //   });
    // }    


  }
 toggleProjectTable(divName: string): void {
    if (divName === 'divA') {
      this.animationState = this.animationState === 'out' ? 'in' : 'out';
    }
  }
  
 toggleTable(divName: string): void {
  if (divName === 'divAA') {
    this.animationStates = this.animationStates === 'out' ? 'in' : 'out';
  }
  }

  toggleShowDiv(divName: string) {
    if (divName === 'divA1') { 
      this.chart1 = !this.chart1;
      this.animationStat = this.animationStat === 'out' ? 'in' : 'out';
    }
  }
  toggleShowDiv2(divName: string) {
    if (divName === 'divA2') { 
      this.chart2= !this.chart2;
      this.animationState1 = this.animationState1 === 'out' ? 'in' : 'out';
    }
  }
  toggleShowDiv3(divName: string) {
    if (divName === 'divA3') { 
      this.chart3= !this.chart3;
      this.animationState2 = this.animationState2 === 'out' ? 'in' : 'out';
    }
  }
  toggleShowDiv4(divName: string) {
    if (divName === 'divA4') { 
      this.chart4= !this.chart4;
      console.log(this.animationState3);
      this.animationState3= this.animationState3 === 'out' ? 'in' : 'out';
    }
  }
  toggleShowDiv5(divName: string) {
    if (divName === 'divA5') { 
      this.chart5=  !this.chart5;
      this.animationState4= this.animationState4 === 'out' ? 'in' : 'out';
    }
  }
  toggleShowDiv6(divName: string) {
    if (divName === 'divA6') { 
      this.chart6= !this.chart6;
      this.animationState5= this.animationState5 === 'out' ? 'in' : 'out';
    }
  }
  toggleShowDiv7(divName: string) {
    if (divName === 'divA7') { 
      this.chart7= !this.chart7;
      this.animationState6= this.animationState6 === 'out' ? 'in' : 'out';
    }
  }
  toggleShowDiv8(divName: string) {
    if (divName === 'divA8') { 
      this.chart8= !this.chart8;
      this.animationState7= this.animationState7 === 'out' ? 'in' : 'out';
    }
  }
  toggleShowDiv9(divName: string) {
    if (divName === 'divA9') { 
      this.chart9= !this.chart9;
      this.animationState8= this.animationState8 === 'out' ? 'in' : 'out';
    }
  }
  toggleShowDiv10(divName: string) {
    if (divName === 'divs1') { 
      this.chart10= !this.chart10;
      this.animationState9= this.animationState9 === 'out' ? 'in' : 'out';
    }
  }
  toggleShowDiv11(divName: string) {
    if (divName === 'divs2') { 
      this.chart11= !this.chart11;
      this.animationState10= this.animationState10 === 'out' ? 'in' : 'out';
    }
  }
  test(){
    const observables: Observable<any> = this.projects.map((project) => {
    return this.mapService.getXLSXData(project.maps?.excelData?.excelName);  
  });
  

  return forkJoin(observables).pipe(
    switchMap((responses: any) => {
      this.projectsPercentageArray = [];
      // console.log(responses , 'sadsad')
      const percentage = [];
      this.userId = this.projects.find((project) => project?.maps?.excelData?.userId === this.creator.email)
      this.projectAchivedResult = [];
      this.projectPlannedResult = [];
      // console.log(this.userId , 'userId')
      
      // if (this.userId) {
        this.onTimeProject = []
        const order = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        const currentDate = new Date();
        const currentMonth = currentDate.toLocaleString('en-US', { month: 'short' });
        const currentYear = currentDate.getFullYear();

        this.targetData = [];

        const header = responses.find((resp)=> resp?.features?.length)
        // console.log(header) 
        for (const key in header.features[0]?.properties) {

          const [month, year] = key.split("'").map(item => item.trim());

          if (year !== undefined) {
            const numericalPart = year.match(/\d+/g).map(Number);
            if (numericalPart[1] === undefined) {
              numericalPart[1] = 0
            }
          }
          if (
            (parseInt(year, 10) + 2000 <= currentYear)
          ) {

            if (parseInt(year, 10) + 2000 == currentYear) {
              const currentWeekIndex = Math.ceil(currentDate.getDate() / 7);
              const findCurrentMonthIndex = order.findIndex((res) => res == currentMonth)
              const findMonthIndex = order.findIndex((res) => res == month)
              let numericalPart
              if (year !== undefined) {
                numericalPart = year.match(/\d+/g).map(Number);
                if (numericalPart[1] === undefined) {
                  numericalPart[1] = 0
                }
              }
              this.targetData.push(key)

              if (findCurrentMonthIndex === findMonthIndex && numericalPart[1] + 1 === currentWeekIndex) {
                break;
              }
            } else {
              this.targetData.push(key)
            }

          }
        }
        responses.forEach((res, index) => {
          let totalPlannedResult = 0;
          let totalAchivedResult = 0;
          let milestoneOnTime = 0;
          let achivedOnTime = 0;
          let projectAchived = [];
          let projectPlanned = [];
          let tempObj = []
          let tempObj1 = []
          // console.log(res.features,"featuress");

          let projectPlannedTotal = 0;
          let projectAchivedTotal = 0;
          
          if(res.features == undefined){
            // this.snackbar.open('Excel not uploaded', 'Close', {
            //   duration: 3000,
            //   panelClass: ['green-snackbar', 'custom-snackbar']
            // });
            return
          }else{

          
          // if (res.features && Array.isArray(res.features)) {
          if(res?.features?.length){
            const percentageOfWork = 100 / (res.features.length / 2);
            this.projectsTargetData = [];
            for (const key in res.features[0].properties) {
              const [weekPart, _, month1, yearPart] = key.split(/['.\s]/);
              if (yearPart) {
                const yearly = yearPart.length === 4 ? yearPart : `20${yearPart}`;
                const currentYear = new Date().getFullYear();
                const currentMonth1 = new Date().getMonth() + 1; // Months are zero-based, so add 1

                if (Number(yearly) > currentYear || (Number(yearly) === currentYear && this.monthGreaterThanCurrentMonth(month1, currentMonth1))) {
                  break;
                }
              }
              const parts = key.split("'");
              const monthYearPart = parts[0].split(' ');
              const month = monthYearPart[monthYearPart.length - 1];
              const year = parts[1];


              if (year !== undefined) {
                const numericalPart = year.match(/\d+/g).map(Number);
                if (numericalPart[1] === undefined) {
                  numericalPart[1] = 0
                }
              }
              if (
                (parseInt(year, 10) + 2000 <= currentYear)
              ) {
                if (parseInt(year, 10) + 2000 == currentYear) {
                  const currentWeekIndex = Math.ceil(currentDate.getDate() / 7);
                  const findCurrentMonthIndex = order.findIndex((res) => res == currentMonth)
                  const findMonthIndex = order.findIndex((res) => res == month)
                  const numericPart = monthYearPart[0].match(/\d+/);
                  const result = numericPart ? Number(numericPart[0]) : null;
                  this.projectsTargetData.push(key)

                  if (findCurrentMonthIndex === findMonthIndex && (result === currentWeekIndex || (result === 4 && currentWeekIndex === 5))) {
                    break;
                  }
                } else {
                  this.projectsTargetData.push(key)
                }

              }
            }
            let perWork = res.features.length/2;
            res.features.forEach((featureRes) => {
              let total = 0;
              let totalCount = 0;
              let tillCurrentCount = 0;

            let count = 0
            let count1 = 0
            if(featureRes.properties['Description'] !== 'nan'){
              for (const key1 in featureRes.properties) {
                if(featureRes.properties[key1] !== 'nan'){
                  count = count + 1
                }
              }
              tempObj.push({name:featureRes.properties['Description'],planned:count })
            }else {
              // console.log(featureRes.properties)
              for (const key1 in featureRes.properties) {
                let findProperty = this.targetData.find((res) => res === key1)
                // console.log(findProperty,'dsadsad')
                if(findProperty && featureRes.properties[findProperty] !== 'nan'){
                  // console.log(featureRes.properties[findProperty])
                  count1 = count1 + 1
                }
              }
              tempObj1.push({achieved:count1 })
            }
            var array3 = tempObj.map((obj, index) => ({
              ...obj,
              ...tempObj1[index]
            }));
          // var array3Alternative = tempObj.map((obj, index) => Object.assign({}, obj, tempObj1[index]));
          
          // console.log(array3);

          //   console.log(tempObj,tempObj1)
            // return;


              if (featureRes?.properties['Description'] !== 'nan') {
                for (const property in featureRes.properties) {
                  const [month, year] = property.split("'").map(item => item.trim());

                  if (year &&  featureRes.properties[property] !== "nan") {
                    totalCount = totalCount + 1;
                    const findTillProperty = this.projectsTargetData.find((res) => res === property)

                    if (findTillProperty) {
                      tillCurrentCount = tillCurrentCount + 1
                    }
                  }
                }
                const tillDatePercentage = (tillCurrentCount/ totalCount) * 100;
                const perWorkPercentage = 100/perWork;
                projectPlannedTotal = projectPlannedTotal + Number(((tillDatePercentage*perWorkPercentage)/100))
              this.totalCount = totalCount;
            } 
            else {
                // console.log("featureRes?.properties['Description'] ====================>>>",featureRes?.properties['Description'])
                // for (const property in featureRes.properties) {
                //   const [month, year] = property.split("'").map(item => item.trim());

                //   if (year &&  featureRes.properties[property] !== "nan") {
                //     // totalCount = totalCount + 1;
                //     const findTillProperty = this.projectsTargetData.find((res) => res === property)

                //     if (findTillProperty) {
                //       tillCurrentCount = tillCurrentCount + 1
                //     }
                //   }
                // }

                // const tillDatePercentage = (tillCurrentCount/ this.totalCount) * 100;
                // const perWorkPercentage = 100/perWork;
                // projectAchivedTotal = projectAchivedTotal + Number(((tillDatePercentage*perWorkPercentage)/100).toFixed(2))
              }
          })
          
          if(this.projects[index]?.status === "Running"){
          this.projectsPercentageArray.push({name:this.projects[index].item, plannedValue:projectPlannedTotal.toFixed(2), id:this.projects[index].id})
          }
        
      }
        }
        // console.log(this.mileStoneTotalResult ,'sddsad', this.achivedTotalResult)
          const milestoneLastMonth = this.mileStoneTotalResult - milestoneOnTime;
          const achivedLastMonth = this.achivedTotalResult - achivedOnTime;
          // console.log(milestoneLastMonth , achivedLastMonth )
          if(milestoneLastMonth === achivedLastMonth){
            this.onTimeProject.push({color:"#008000"});
          } else {
            this.onTimeProject.push({color:"#e74c3c"});
          }
          if(totalPlannedResult){
            this.projectPlannedResult.push(totalPlannedResult);  
            this.projectAchivedResult.push(totalAchivedResult);  
          }
          if(projectAchived?.length){
            this.achivedData.push(projectAchived);
          } 
          if(projectPlanned?.length){
            this.plannedData.push(projectPlanned);
          }
      })
      // }
    //    else {
    //     this.resultHierarchy = []
    //     this.onTimeProject = []
    //   responses.forEach((resp) => {
    //     if(resp?.features){
    //       this.resultHierarchy.push(this.buildHierarchy(resp))
    //     }
    //   })
    //   this.resultHierarchy.forEach((resp) => {
    //     if(resp?.length){
    //     this.achivedTotalResult = 0;
    //     this.mileStoneTotalResult = 0;
    //     let milestoneOnTime = 0;
    //     let achivedOnTime = 0;
    //     const result = resp.find((res) => res.part === 'nan')
    //     const keys = [];
    //     for (let key in result.data.properties) {
    //       if (key !== 'id' && result.data.properties[key] !== 'nan' && new Date(result.data.properties[key]) < new Date()) {
    //         keys.push(key)
    //       }

    //     }
    //     resp.forEach((res) => {
    //       let milestoneTotal = 0;
    //       let achivedTotal = 0;

    //       if (res.children?.length) {
    //         res.children?.forEach((outerRes) => {

    //           if (outerRes.children?.length) {
    //             let outerResultCompleted = 0;
    //             outerRes.children.forEach((innerResp) => {
    //               let totalCompleted = 0;
    //               let milestonesCount = 0;
    //               let achivedCount = 0;
    //               for (let data of keys) {
    //                 if (milestonesCount !== 3) {
    //                   const foundPair = Object.entries(innerResp.data.properties).find(([key, value]) => key === data);
    //                   if (foundPair && foundPair[1] !== 'nan') {
    //                     totalCompleted += Number(foundPair[1])
    //                   }
    //                   milestonesCount++;
    //                 } else {
    //                   achivedCount++;
    //                   if (achivedCount === 3) {
    //                     achivedCount = 0;
    //                     milestonesCount = 0;
    //                   }
    //                 }
    //               }
    //               outerResultCompleted += ((((totalCompleted * innerResp.data.properties['Level 2']) * 100) / 100) * 100)
    //             })
    //             milestoneTotal += outerResultCompleted * outerRes.data.properties['Level 1']
    //           }
    //           if (outerRes.children?.length) {
    //             let outerResultCompleted = 0;
    //             outerRes.children.forEach((innerResp) => {
    //               let totalCompleted = 0;
    //               let milestonesCount = 0;
    //               let achivedCount = 0;
    //               for (let data of keys) {
    //                 if (milestonesCount !== 3) {
    //                   milestonesCount++;
    //                 } else {
    //                   const foundPair = Object.entries(innerResp.data.properties).find(([key, value]) => key === data);
    //                   if (foundPair && foundPair[1] !== 'nan' && foundPair[1] !== 'NaT') {
    //                     totalCompleted += Number(foundPair[1])
    //                   }
    //                   achivedCount++;
    //                   if (achivedCount === 3) {
    //                     achivedCount = 0;
    //                     milestonesCount = 0;
    //                   }
    //                 }
    //               }
    //               outerResultCompleted += ((((totalCompleted * innerResp.data.properties['Level 2']) * 100) / 100) * 100)
    //             })
    //             achivedTotal += outerResultCompleted * outerRes.data.properties['Level 1']
    //           }
    //         })
    //         this.achivedTotalResult += achivedTotal;
    //         this.mileStoneTotalResult += milestoneTotal;
    //       }
    //       achivedOnTime = achivedTotal;
    //       milestoneOnTime = milestoneTotal;
    //     })
    //       const milestoneLastMonth = this.mileStoneTotalResult - milestoneOnTime;
    //       const achivedLastMonth = this.achivedTotalResult - achivedOnTime;
    //       if(milestoneLastMonth === achivedLastMonth){
    //         this.onTimeProject.push({color:"#008000"});
    //       } else {
    //         this.onTimeProject.push({color:"#e74c3c"});
    //       }

    //     this.mileStoneArray.push(this.mileStoneTotalResult);
    //     this.achivedArray.push(this.achivedTotalResult);
    //   }
    //   })
    // }
      this.dropdownProjects = this.projectsPercentageArray.filter((resp) => parseFloat(resp.plannedValue) !== 0);
      this.projects.forEach((project, index) => {
        let projectTotalAchived = 0;
          const perWorkPercentage =  100 / (responses[index].features?.length/2)
          console.log(perWorkPercentage,'dsadsadsa')
          this.kmlData = project?.tempKmlData
          project?.kmlData.forEach(element => {
            if (element.name.includes('Planned')) {
              this.plannedRoad = element.measurement
            }
          });
          const data = this.dropdownProjects.some((res) => res.name === project.item)
          // console.log(this.projects,"data=====>data")
          if (data) {
            const filteredKMLData = this.getLastEntries(this.kmlData);
            filteredKMLData?.forEach((element, index) => {
              if (element.name.includes('Achieved')) {
                const value = this.plannedRoad != 0 ? ((element.measurement / this.plannedRoad)*perWorkPercentage).toFixed(2) : 0
                projectTotalAchived = projectTotalAchived + Number(value);
              }
            })
          }
        this.projectsPercentageArray = this.projectsPercentageArray.map((res) => {
          if (res.id === project.id) {
              return {
                  ...res,
                  achivedValue: projectTotalAchived
              };
          }
          return res;
      });
      
      

      })
      return of(percentage);
    
    })
  );
}


getLastEntries(data: any[]): any[] {
  const uniqueEntries = new Map<string, any>();

  data.forEach(item => {
    // Normalize the name by trimming spaces, removing line breaks, and converting to lower case
    const baseName = item.name.split('_').slice(0, 2).join('_').trim().replace(/\s+/g, ' ').toLowerCase();
    uniqueEntries.set(baseName, item);
  });

  return Array.from(uniqueEntries.values());
}

monthGreaterThanCurrentMonth(month, currentMonth) {
  const monthsInOrder = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
  const providedMonthIndex = monthsInOrder.indexOf(month);
  
  return providedMonthIndex > currentMonth - 1;
}

 openCommentForm() {
  // Add logic here to open the form when the comment icon is clicked
  console.log('Comment icon clicked! Open the form.');
  // You can implement your form opening logic here, for example, showing a modal or another component.
}

filterUsers(value: string): string[] {
  if (typeof value !== 'string') {
    return [];
  }
  
  const filterValue = value.toLowerCase();
  
  if (filterValue.includes('@')) {
    const searchTerm = filterValue.substring(filterValue.lastIndexOf('@') + 1);
    const data = this.users.filter(user => 
    user.username && typeof user.username === 'string' && user.username.toLowerCase().includes(searchTerm)
    );
    return data
  } else {
    return [];
  }
  }

  callProjectCompletionDays(name , data, projects){
    console.log("=====================>>>",data);
    console.log("=========================??pro",projects);
    let chartData = [];

    var chartDom = document.getElementById('chart-container-summary');
    var myChart = echarts.init(chartDom);
    let obj = this

    let option = {
      xAxis: {
        type: 'category',
        name:'Projects',
        data: name
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      yAxis: {
        type: 'value',
        name: 'Percentage (%)'
      },
      series: [
        {
          data: data,
          type: 'bar'
        }
      ]
    };


    myChart.setOption(option);
    // console.log(option, 'option');
    myChart.on('click', 'series', (params) => {
    // console.log(params.name, "paramsssssss");

      const clickedProject = this.projects.find(element => element.item === params.name);
      // console.log(clickedProject,"click");
      this.selectProject= clickedProject
      this.getProjectChart()
      if (clickedProject) {
        console.log("Project ID:", clickedProject.id);
      } else {
        console.log("Project not found.");
      }
    });
  }

  getUserComments() {
    // this.mapService.getComments().subscribe((resp: any) => {
    //   this.userComments = resp.data
    // })
  }

  selectUserFn(user): void {
    this.selectedUser = user;
    this.commentForm.get('message').setValue(`@${user.username}`+' ');
  }

  openDialog(templateRef, comment?: any) {
    let temp = '';
    if (localStorage.getItem('email') === 'osnagarnigam@rediffmail.com') {
      temp = 'Pmis';
    } else if (localStorage.getItem('email') === 'vishvaraj@heliware.co.in') {
      temp = 'Vishvaraj';
    }
    else {
      temp = " "
    }
    this.mapService.getMcgUsers({type:temp}).subscribe(res => this.users = res)
    if (this.editComment) {
      this.commentForm.get('name').disable();
      this.commentForm.get('name').setValue(comment.name);
      this.commentForm.get('message').setValue(comment.message);
    }
    const dialogRef = this.dialog.open(templateRef, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.editComment) {
          const updateComment = {
            name: result.value.name,
            id: comment.id,
            message: result.value.message,
          }
          this.editComment = false
          this.mapService.updateComments(updateComment).subscribe((resp: any) => {
            let upadteUserComment = this.userComments.find((res) => res.id === resp.comment._id);
            upadteUserComment.message = resp.comment.message
            this.commentForm.reset();
          })
        } else if (this.reply) {
          const updateComment = {
            name: result.value.name,
            id: comment.id,
            message: result.value.message,
          }
          console.log("comment =================>>>", comment);
          console.log(result.value)
          this.reply = false
          this.mapService.updateComments(updateComment).subscribe((resp: any) => {
            let upadteUserComment = this.userComments.find((res) => res.id === resp.comment._id);
            console.log(upadteUserComment)
            console.log(resp)
            upadteUserComment.reply = resp.comment.reply

          })
        } else {
          const data = result;
          if (this.selectedUser && this.selectedUser.id) {
            data.value.to = this.selectedUser.id;
          }

          const findProject = this.dropdownProjects.find((resp)=> resp.id === data.value.projectId)
          
          if(findProject) {
            data.value.projectName = findProject.name
          }

          if (this.creator && this.creator.id) {
            data.value.from = this.creator.id;
          }
          this.mapService.addComments(data.value).subscribe((res) => { })
        }
      } else {
        this.reply = false;
        this.editComment = false;
      }
      this.commentForm.get('name').enable();
      this.commentForm.reset();
      // this.getUserComments();
    });
  }

  replyComment(template, comment) {
    this.reply = true
    this.openDialog(template, comment)
  }

  startComment(templateRef) {
    this.getUserComments();
    this.dialog.open(templateRef, {
      width: '500px',
      height: '400px'
    }).afterClosed()
    console.log("start")
  }

  editCommentFn(template, comment) {
    console.log(comment)
    this.editComment = true;
    console.log("edit ==========>>>>", this.editComment);
    this.openDialog(template, comment)
  }
  deleteComment(comment){
    this.mapService.deleteComment(comment.id).subscribe((res)=>{
      this.getUserComments();
    })

  }
    onSubmit(data){
    console.log(data.value)
    this.creator.email
    this.mapService.addComments({name:data.value,email:this.creator.email}) 
}

buildHierarchy(data): HierarchyNode[] {
  const hierarchy: HierarchyNode[] = [];

  data.features?.forEach(item => {
    console.log(item.properties)
    const parts = item.properties["SI. No."]?.split('.');
    let currentLevel: HierarchyNode[] = hierarchy;

    parts.forEach((part, index) => {
      const existingNode = currentLevel.find(node => node.part === part);

      if (existingNode) {
        // Node already exists, move to the next level
        currentLevel = existingNode.children;
      } else {
        // Create a new node
        const newNode: HierarchyNode = { part, children: [] };

        // If it's the last part, store the additional properties
        if (index === parts.length - 1) {
          newNode.data = { ...item };
        }

        // Add the new node to the current level
        currentLevel.push(newNode);

        // Move to the next level
        currentLevel = newNode.children;
      }
    });
  });

  return hierarchy;
}
  

  // callProjectCompletionDays(){
  //     // console.log(this.achivedTotalResult)
  //   var chartDom = document.getElementById('chart-container-summary');
  //   var myChart = echarts.init(chartDom);
  //   let obj = this
  //   let option = {
  //     xAxis: {
  //       type: 'category',
  //       name:'Projects',
  //       data: name
  //     },
  //     tooltip: {
  //       trigger: 'axis',
  //       axisPointer: {
  //         type: 'shadow'
  //       }
  //     },
  //     yAxis: {
  //       type: 'value',
  //       name: 'Percentage (%)',
  //     },
  //     series: [
  //       {
  //         data: [this.achivedTotalResult],
  //         type: 'bar'
  //       }
  //     ]
  //   };

  //   myChart.setOption(option);

  //   myChart.on('click', 'series', (params) => {
  //     // this.handleChartSeriesClick(params)
  //     console.log(params)
  //     obj.showChart =false
  //     obj.parentData = {
  //     projectId: obj.form.controls.selectedProject.value.id,
  //     siteId: obj.form.controls.selectedSite.value.id
  //   }
  //   console.log(obj.ShapeLayerData)
  //   obj.sitesService.fetchSitesData(obj.creatorid)
  // .pipe(
  //   tap((res)=> { obj.siteId = res[0].id}),
  //   switchMap(()=>{
  //     // console.log(this.sites)
  //     return obj.mapService.getProjects(obj.siteId) 
  //   }),
  // )
  // .subscribe((res)=> console.log(res));
  // this.addGeojsonToMap();
  //   })
  // }

  getSelectedProcess(event){
    this.chartData(event.value)
  }
  handleChartSeriesClick() {

    let name = [];
    this.projects.forEach((res)=>{
      if(res.maps?.excelData?.excelName){
        name.push(res.item);
      }
    })
    console.log(this.onTimeProject,name)
    for(let i = 0; i <name?.length;i++){
      if(this.onTimeProject.length){
      if(this.onTimeProject[i] == undefined){

      }else{
        this.onTimeProject[i].name = name[i] 
      }

      }
  }
  // console.log(this.achivedArray , this.projectAchivedResult)
    const formatNumberWithCommas = (number) => {
      return parseFloat(number).toLocaleString('en-US'); 
    };

    var app: any = {};
    
    var chartDom = document.getElementById('chart-container-summary');
    
    var myChart = echarts.init(chartDom);
    const posList = [
      'left',
      'right',
      'top',
      'bottom',
      'inside',
      'insideTop',
      'insideLeft',
      'insideRight',
      'insideBottom',
      'insideTopLeft',
      'insideTopRight',
      'insideBottomLeft',
      'insideBottomRight'
    ];
    app.configParameters = {
      rotate: {
        min: -90,
        max: 90
      },
      
      align: {
        options: {
          left: 'left',
          center: 'center',
          right: 'right'
        }
      },
      verticalAlign: {
        options: {
          top: 'top',
          middle: 'middle',
          bottom: 'bottom'
        }
      },
      position: {
        options: posList.reduce(function (map, pos) {
          map[pos] = pos;
          return map;
        }, {})
      },
      distance: {
        min: 0,
        max: 100
      }
    };
    app.config = {
      rotate: 90,
      align: 'left',
      verticalAlign: 'middle',
      position: 'insideBottom',
      distance: 15,
      onChange: function () {
        const labelOption = {
          rotate: app.config.rotate,
          align: app.config.align,
          verticalAlign: app.config.verticalAlign,
          position: app.config.position,
          distance: app.config.distance
        };
        myChart.setOption({
          series: [
            {
              label: labelOption
            },
            {
              label: labelOption
            },
          ]
        });
      }
    };

    const labelOption = {
      
      show: true,
      position: app.config.position,
      distance: app.config.distance,
      align: app.config.align,
      verticalAlign: app.config.verticalAlign,
      rotate: app.config.rotate,
      formatter: '{c}  {name|{a}}',
      fontSize: 16,
      rich: {
        name: {}
      }
    };

    const option = {
      tooltip: {
        trigger: 'item',
        formatter: (params ) => {
          const data = `${params.name}: ${params.value}%<br/>`
          return data;
        }
      },
      xAxis: [
        {
          // axisLabel: {
          //   formatter: function (value) {
          //     return value.split(' ')[0];
          //   }
          // },
          axisLabel: {
            interval: 0,
            margin: 20,
            width: "50",
            // rotate: 30 ,//If the label names are too long you can manage this by rotating the label.,
            overflow: 'truncate',
          },
          name: 'Description of Items',
          nameLocation: 'middle',
          nameGap: 50,
          type: 'category',
          axisTick: { show: false },
          data: name
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: 'Percent (%)' ,
          nameLocation: 'middle',
          nameGap: 100,
        }
      ],
      series: [
        {
          name: 'Planned',
          type: 'bar',
          barGap: 0,
          label: {
            ...labelOption,
            formatter: (params) => {
              return formatNumberWithCommas(params.value) + " " + "Planned";
            },
          },
          emphasis: {
            focus: 'series'
          },
          data: this.mileStoneArray?.length ? this.mileStoneArray : this.projectPlannedResult
        },
        {
          name: 'Achived',
          type: 'bar',
          label: {
            ...labelOption,
            formatter: (params) => {
              return formatNumberWithCommas(params.value) + " " + "Achived";
            },
          },
          emphasis: {
            focus: 'series'
          },
          // itemStyle: {
          //   color: (params)=>{
          //     const result = this.onTimeProject.find((res)=>res.name === params.name)
          //     return result?.color
          //   },
          // },
          data: this.achivedArray?.length ? this.achivedArray : this.projectAchivedResult
        },
      ]
    };
    
    myChart.setOption(option);
        myChart.on('click', 'series', (params) => {
          // console.log(params)
          const clickedProject = this.projects.find(element => element.item === params.name);
          // console.log(clickedProject,"click");
          const  userId = this.projects.find((project)=> project?.maps.excelData.userId === this.creator.email) 
          if(userId){
            // this.projectAchivedResult = [];
            // this.projectPlannedResult = [];
            // this.handleChartSeriesClick() 
          } else {
            this.selectProject= clickedProject
            // console.log(this.form)
            this.getProjectChart()
          } 

      // this.handleChartSeriesClick(params)
    //   obj.showChart =false
    //   obj.parentData = {
    //   projectId: obj.form.controls.selectedProject.value.id,
    //   siteId: obj.form.controls.selectedSite.value.id
    // }
  })
  }


  callChart(data, name){
    let chartData = []
    for(let i=0;i<data.length;i++){
      chartData.push({ value: data[i], name: name[i]})
    }
    var chartDom = document.getElementById('chart-no-of-project');
    var myChart = echarts.init(chartDom);
    console.log(myChart,'=========call')
    let obj = this
    const option = {
  tooltip: {
    trigger: 'item'
  },
  title: {
    text: 'Total Number Of Projects',
    left: 'center',
    textStyle: {
      color: 'White'
    },
  },
  legend: {
    top: '10%',
    left: 'center',
    color:'red',
    textStyle: {
      color: 'White'
    },
  },
  series: [
    {
      type: 'pie',
      top:'20%',
      left: '60px',
      radius: ['40%', '80%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'center'
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 20,
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: chartData
    }
  ]
};


  
    myChart.setOption(option);
    console.log(myChart,'---------------charts');
    
      myChart.on('click', 'series', (params) => {
      console.log( myChart, '=================param')
      let selectedValue : any = this.sites.find(res => res.name == params.name)
      // console.log(selectedValue)
      obj.form.controls.selectedSite.value = selectedValue
      this.getProjectData('a')
    })
  }

  

  chartShowStatus(temp){  
    this.isNotVisible=true;  
    this.showDiv= false;
    this.categoryChart = true;
    const containerWidth = 732;
    const containerHeight = 374;
    const maxDataPoints = 10; 
    let data = []  
console.log(temp,'temp')
    for (const property in temp) {
      
    
      let obj = {}

      temp[property].forEach(e => {
        if (obj.hasOwnProperty(e['status'])){
          obj[e['status']] +=1
        }else{
          obj[e['status']] = 1
        }
      });
      console.log(obj)
      const dataEntry = { name: property, ...obj };
      data.push(dataEntry);
    }

    if (data.length > maxDataPoints) {
      data = data.slice(0, maxDataPoints);
    }

    let names = [];

    data.forEach((res) => {
      for (let key of Object.keys(res)) {
        const exists = names.find((name) => name === key);
        if (!exists && key !== "name") {
          names.push(key);
        }
      }
    });
    
    data.forEach((res) => {
      for (let key of names) {
        if (!res.hasOwnProperty(key) && key !== "name") {
          // Add the key with a default value of 0
          res[key] = 0;
        }
      }
    });
  
    setTimeout(() => {
      const chartDom = document.getElementById('chart-container-status-summary1');

      if (this.myChart) {
          this.myChart.dispose();
      }

      let myChart = echarts.init(chartDom);


      const labelSetting: echarts.PictorialBarSeriesOption['label'] = {
        show: true,
        position: 'right',
        offset: [10, 0],
        fontSize: 16
      };

      function makeOption(
        type: 'bar' | 'pictorialBar',
        symbol?: string
      ): echarts.EChartsOption {
        const yAxisData = data.map(item => item.name);
      
        const series = Object.keys(data[0]).slice(1).map(key => ({
          name: key,
          id: `bar_${key}`,
          type: type as 'pictorialBar',
          barGap: '10%',
          label: labelSetting,
          symbolRepeat: true,
          symbolSize: ['80%', '60%'],
          universalTransition: {
            enabled: true,
            delay: function (idx, total) {
              return (idx / total) * 1000;
            }
          },
          data: data.map(item => ({
            value: item[key],
            symbol: symbol
          }))
        }));
        console.log(series)
        return {
          title: {
            text: 'Project Running Status',
            left: 'center',
            textStyle: {
              color: '#ffffff' 
            }
          },
          legend: {
            data: series.map(serie => serie.name),
            left: '10%',
            top:'15%',
            textStyle: {
              color: '#ffffff' 
            }
          },
          name: names,
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            },
          },
          grid: {
            containLabel: true,
            left: 20,
            bottom: 15
          },
          yAxis: {
            data: yAxisData,
            inverse: true,
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: {
              margin: 30,
              fontSize: 14,
              color: '#ffffff' 
            },
            axisPointer: {
              label: {
                show: true,
                margin: 30
              }
            }
          },
          xAxis: {
            splitLine: { show: false },
            axisLabel: { show: false },
            axisTick: { show: false },
            axisLine: { show: false }
          },
          animationDurationUpdate: 500,
          series: series
        };
      }
      
      const options = [
        makeOption('bar'),
        makeOption('pictorialBar', 'diamond')
      ];
      
      
      let optionIndex = 0;
      const option = options[optionIndex];

      if (this.interval) {
          clearInterval(this.interval);
      }

      const interval = setInterval(() => {
          optionIndex = (optionIndex + 1) % options.length;
          myChart.setOption(options[optionIndex]);
      }, 2500);

      myChart.setOption(option);

      this.myChart = myChart;
      this.interval = interval;
  }, 500);
   
  }

  tempChart(){
    // alert()
    // this.onTimeProject = ['ds']
    console.log(this.projectsPercentageArray,'projectsPercentageArray')
    let name = [];
  //   this.projects.forEach((res)=>{
  //     if(res.maps?.excelData?.excelName){
  //       name.push(res.item);
  //     }
  //   })
  //   console.log(this.onTimeProject,name)
  //   for(let i = 0; i <name?.length;i++){
  //     if(this.onTimeProject.length){
  //     if(this.onTimeProject[i] == undefined){

  //     }else{
  //       this.onTimeProject[i].name = name[i] 
  //     }

  //     }
  // }
  // console.log(this.achivedArray , this.projectAchivedResult)
    const formatNumberWithCommas = (number) => {
      return parseFloat(number).toLocaleString('en-US'); 
    };
    var app: any = {};
    
    var chartDom = document.getElementById('chart-container-summary');

    var myChart = echarts.init(chartDom);
    const posList = [
      'left',
      'right',
      'top',
      'bottom',
      'inside',
      'insideTop',
      'insideLeft',
      'insideRight',
      'insideBottom',
      'insideTopLeft',
      'insideTopRight',
      'insideBottomLeft',
      'insideBottomRight'
    ];
    app.configParameters = {
      rotate: {
        min: -90,
        max: 90
      },
      align: {
        options: {
          left: 'left',
          center: 'center',
          right: 'right'
        }
      },
      verticalAlign: {
        options: {
          top: 'top',
          middle: 'middle',
          bottom: 'bottom'
        }
      },
      position: {
        options: posList.reduce(function (map, pos) {
          map[pos] = pos;
          return map;
        }, {})
      },
      distance: {
        min: 0,
        max: 100
      }
    };
    app.config = {
      rotate: 90,
      align: 'left',
      verticalAlign: 'middle',
      position: 'insideBottom',
      distance: 15,
      onChange: function () {
        const labelOption = {
          rotate: app.config.rotate,
          align: app.config.align,
          verticalAlign: app.config.verticalAlign,
          position: app.config.position,
          distance: app.config.distance
        };
        myChart.setOption({
          series: [
            {
              label: labelOption
            },
            {
              label: labelOption
            },
          ]
        });
      }
    };

    const labelOption = {
      show: true,
      position: app.config.position,
      distance: app.config.distance,
      align: app.config.align,
      verticalAlign: app.config.verticalAlign,
      rotate: app.config.rotate,
      formatter: '{c}  {name|{a}}',
      fontSize: 16,
      rich: {
        name: {}
      }
    };

    const option = {
      title: {
        text: 'Project Completion Chart' ,
        left: 'center',
        right: 'center',
        textStyle: {
          color: 'White'
        },
      },
      tooltip: {
        trigger: 'item',
        formatter: (params) => {
          const formattedValue = parseFloat(params.value).toFixed(2);
          const data = `${params.name}: ${formattedValue}%<br/>`;
          return data;
        }
      },
      xAxis: [
        {
          axisLabel: {
            formatter: function (value) {
              return value.split(',')[0];
            },
            interval: 0,
            margin: 20,
            width: "50",
            // rotate: 30, // Rotate the label if needed
            overflow: 'truncate',
            textStyle: {
              color: 'White'
            }
          },
          name: 'Description of Items',
          nameLocation: 'middle',
          nameGap: 50,
          nameTextStyle: {
            color: 'White'
          },
          type: 'category',
          axisTick: { show: false },
          data: this.projectsPercentageArray.filter((resp)=> parseFloat(resp.plannedValue) !== 0).map((resp)=> resp.name)
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: 'Percent (%)' ,
          nameLocation: 'middle',
          nameGap: 100,
          nameTextStyle: {
            color: 'White'
          },
          axisLabel: {
            textStyle: {
                color: 'White'
            }
        },
        }
      ],
      series: [
        {
          name: 'Planned',
          type: 'bar',
          barGap: 0,
          label: {
            ...labelOption,
            formatter: (params) => {
              return formatNumberWithCommas(params.value) + "%  " + "Planned";
            },
          },
          emphasis: {
            focus: 'series'
          },
          data: this.projectsPercentageArray.filter((resp)=> parseFloat(resp.plannedValue) !== 0).map((resp)=> resp.plannedValue)
        },
        {
          name: 'Achived',
          type: 'bar',
          label: {
            ...labelOption,
            formatter: (params) => {
              return formatNumberWithCommas(params.value) + "%  " + "Achived";
            },
          },
          emphasis: {
            focus: 'series'
          },
          itemStyle:{
            color:'red'
          },
          // itemStyle: {
          //   color: (params)=>{
          //     const result = this.onTimeProject.find((res)=>res.name === params.name)
          //     return result?.color
          //   },
          // },
            data:this.projectsPercentageArray.filter((resp)=> parseFloat(resp.plannedValue) !== 0).map((resp)=> resp.achivedValue)
        },
      ]
    };
    myChart.setOption(option);

    // myChart.on('click', 'series', (params) => {
    //   this.showProjectTable= false;
    //   this.showNoProject =false;

    //   // this.projectSummary= false;
    //   this.categoryChart=false;
    //   // console.log(params.name ,this.projects)
    //   const clickedProject = this.projects.find(element => element.item === params.name);
    //   // console.log(clickedProject,"click");
    //   this.selectProject = clickedProject
    //   this.getProjectChart()
    //   // console.log(params.data['name'] , this.sites)
    //   //obj.categoryChart = true
    //   // obj.chartShowStatus(temp ,params.data['name'])
    // })
  }
  


  // New Changes FOr PMIS

  chartShowStatusPie(temp){
    this.projectSummary =true;
    this.categoryChart = false
    let data = []
    for (const property in temp) {
      data.push({value: temp[property].length, name: property} )
    }
    // console.log(data)
    setTimeout(() => {
      var chartDom = document.getElementById('chart-container-status-summary');
    var myChart = echarts.init(chartDom);
    let obj = this
    let option = {
        title: {
          text: 'Project Category',
          subtext: '',
          left: 'center',
          textStyle: {
            color: '#ffffff' 
          }

        },
      tooltip: {
        trigger: 'item'
      },
      legend: {
        orient: 'horizontal',
        left: 'center',
        top: '10%',
        textStyle: {
          color: '#ffffff'
        }
      },
      series: [
        {
          name: 'Category',
          type: 'pie',
          radius: '50%',
          top:'20%',
          data:data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };

    myChart.setOption(option);
    myChart.on('click', 'series', (params) => {
      obj.projectSummary=false;
      // console.log(params.data['name'] , this.sites)
      obj.categoryChart = false 
      obj.chartShowStatus(temp)
    })
    }, 1000);

  }

  async getProjectOverviewData(){
    let data = await this.mapService.getXlsxGeoJsonFromPostgres('overall_summary_r3_ivjxp3bhcitbwdouh8eg8n');
    // let data = await this.mapService.getXlsxGeoJsonFromPostgres('overall_summary_r2_lp7upujl34frtuso37vsvz');
    data['features'].forEach(res => {
      
      res.properties[ 'completed length' ] = "nan"
      delete res.properties[ 'Total Completed  length of roads' ];
      console.log(this.temp)
      this.temp.forEach((e:any) => {
        // console.log(e)
        if(e.item.split(', ')[1] === res.properties['Name of Project'].replace(/,/g, "")){
          this.tableData.push(res.properties)    
        }
      })
      
    })
    this.overAllTabledata = this.tableData
    this.tableData.map((item, index, res) => {
      console.log(item,"itemitem")
      if (item.Department === 'PWD' && item.Status === "Running") {
        this.TotalRunningProjectsPwd++
      }
      if (item.Department === 'PWD') {
        this.pwdCount++;
      }
      if (item.Department === 'Light'){
        this.Light++
      }
      if (item.Department === 'Light/Bridge Corporation'){
        this.lightBridgeCount++
      }
      if (item.Department === 'Light/Bridge Corporation' && item.Status === "Running" ){
        this.TotalRunningProjectsLightBridge++
      }
      if (item.Department === 'Light' && item.Status === "Running" ){
        this.TotalRunningProjectsLight++
      }
 
    }); 
   


    this.totalProjectStarted = this.tableData.length
    // console.log(this.tableData,'---------tablee')
    this.tableData.forEach(res => {
      console.log(res)
      if(res['Budget Sanctioned (in Lakhs)'] !== 'nan'){
       this.budgetSanctioned = this.budgetSanctioned + Number(res['Budget Sanctioned (in Lakhs)']) 
      }
      if(res['Budget Utilised (in Lakh)'] !== 'nan'){
        this.budgetUtilised = this.budgetUtilised + Number(res['Budget Utilised (in Lakh)'])  
      }
      if(res['Budget Transferred (in Lakhs) For 25 %'] !== 'nan'){
        this.budgetTransferred = this.budgetTransferred + Number(res['Budget Transferred (in Lakhs) For 25 %']) 
      }
      if(res['Status'] === "Running"){
        this.projectStarted = this.projectStarted + 1
      }
      if(res['Status'] === "Re-Tendered"){
        this.projectRetendered = this.projectRetendered+ 1
      }
      if(res['Total Planned length of roads (in m)'] !== 'nan'){
        this.totalPlannedRoad = this.totalPlannedRoad + Number(res['Total Planned length of roads (in m)'])
      }
      if(res['completed length'] !== 'nan'){
        this.totalCompleteRoad = this.totalCompleteRoad + Number(res['completed length'])
      }
      console.log(this.totalCompleteRoad,'kength')
      if(res['Total No. of Poles'] !== 'nan'){
        this.totalPoles = this.totalPoles + Number(res['Total No. of Poles'])
      }
      if(res['Total No. of Bulbs'] !== 'nan'){
        this.totalBulbs = this.totalBulbs + Number(res['Total No. of Bulbs'])
      }
    })
    console.log(this.tableData)
    this.tableHeader = Object.keys(this.tableData[0])
    this.tableHeader.pop()
    let budgetData = this.calculateBudget(this.tableData);
    console.log(budgetData)
    let budgetCategories = Object.keys(budgetData);
    this.showNoProject=true;
    if(this.currentUser !== 'vishvaraj@heliware.co.in'){
      this.generateBudgetChart([this.budgetSanctioned.toFixed(2),this.budgetTransferred.toFixed(2),this.budgetUtilised.toFixed(2)], ['Fund Sanctioned', 'Fund Transfered' ,'Fund Utilised'], 'chartExcel', 'Project Expense (In Lakhs)');
    }
    // this.getCompletedLength()

  }
  calculateBudget(tableData: any[]): { [key: string]: number } {
    let budgetData: { [key: string]: number } = {};
   
    tableData.forEach(row => {
      Object.keys(row).forEach(key => {
        if (key.toLowerCase().includes('budget')) {
          const value = parseFloat(row[key]);
          if (!isNaN(value)) {
            budgetData[key] = (budgetData[key] || 0) + value;
          }
        }
      });
    });
  
    return budgetData;
  }


  onSearch(value: string) {
    if(value.length){
      this.searchProject = true;
    }
    else if(value.length == 0) {
      this.searchProject = false;
    }
    console.log(this.tableData,value,'objjj')
    this.filteredItems = this.tableData.filter(objData =>
      (objData['Name of Project']?.toLowerCase().includes(value.toLowerCase())) ||
      (objData['Apex Committee']?.toLowerCase().includes(value.toLowerCase())) ||
      (objData['Department']?.toLowerCase().includes(value.toLowerCase())) ||
      (objData['Sl. No.']?.toString().includes(value)) || 
      (objData['Responsibility/Name of Clerk']?.toLowerCase().includes(value.toLowerCase())) ||
      (objData['Status']?.toLowerCase().includes(value.toLowerCase()))
  );
  }

   generateBudgetChart(data: number[], categories: string[], chartDomId: string, chartTitle: string) {
    this.cdr.detectChanges();
    var chartDom = document.getElementById('chartExcel');
    var myChart = echarts.init(chartDom);
    const option = {
      title: {
        text: chartTitle,
        left: 'center',
        right: 'center',
        textStyle: {
          color: 'White'
        },
      },
      label: {
        show: true,
        // position: 'top',
        color: "black",
        // fontSize:12,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01],
        axisLabel: {
          textStyle: {
              color: 'White'
          }
      },
      },
      yAxis: {
        type: 'category',
        data: categories,
        axisLabel: {
          textStyle: {
              color: 'White'
          }
      },
      },
      series: [
        {
          name: 'Budget',
          type: 'bar',
          data: data,
          label: {
            color: 'White' 
          },
        }
      ]
    };
  
    myChart.setOption(option);
  
  }

  getPlannedLength(){
    this.obj = {}
    let re = this
    // this.projects = [];
    console.log(this.sites)
// Create an array of observables for each getProjects call
let observables
if(this.organization == "ADMIN"){
   observables = this.sites.map(res => this.mapService.getProjects(res.id));
}
else{
  observables = this.sites.map(res => this.mapService.fetchAssignedProjects({userid:localStorage.getItem('id'),siteid:res.id}));
}
    let tempObj = {}
    let temp1Obj = {}
    let temp2Obj = {}
    this.featureObject = {};
    let tempObject ={};

  forkJoin(observables)
    .pipe(takeUntil(this.unsubscribeSubject))
    .subscribe((responses: any[]) => {
  
    responses.map((projects, index )=> {
        const updatedProjects = projects.map((project) => {
          return { ...project, category: this.sites[index].category };
        });
        this.projects = [...this.projects, ...updatedProjects];
        this.showProjectsOnMap(this.projects) 
        projects.forEach(async (project: any) => {
          this.Category = project.category;
          // console.log(this.Category,"========>Category")
          if(project.maps.excelData){
          this.mapService.getXLSXData(project.maps.excelData.excelName).subscribe(responses => {

            if(responses?.features){
              const order = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      
              const currentDate = new Date();
              const currentMonth = currentDate.toLocaleString('en-US', { month: 'short' });
              const currentYear = currentDate.getFullYear();
              const monthProperties = Object.keys(responses.features[0]?.properties).filter(key => key.includes("'"));
              // this.weeksChart = true;
              const [weekPart, _, month, yearPart] = monthProperties[0].split(/['.\s]/);
              const yearly = yearPart.length === 4 ? yearPart : `20${yearPart}`;
              const firstMonth = month;
              const firstYear = yearly;
              const firstWeek = parseInt(weekPart);
              const firstDayOfMonth = new Date(`${firstMonth} 01, ${firstYear}`);
              const dayOfWeek = firstDayOfMonth.getDay();
              const firstDayOfTargetWeek = new Date(firstDayOfMonth);
              firstDayOfTargetWeek.setDate(firstDayOfMonth.getDate() - dayOfWeek + firstWeek * 7);
          
              // console.log(firstDayOfTargetWeek)
              temp2Obj[project.item.split(', ')[1]] = firstDayOfTargetWeek
              
              

              // let tempArray = [];
              
              let temp =[];
              responses.features.forEach((featureRes) => {
                if(featureRes?.properties['Description'] !== 'nan'){
                  temp.push(featureRes?.properties['Description'])
                }
              })
              // console.log(temp)
              // featureObject[project.item] = temp
              if (project?.tempKmlData.length) {
                let tempArray = [];
                let tempObj = {};
                
                project.tempKmlData.forEach((e: any) => {
                    let nameParts: any = e.name.split("_");
                    if (nameParts[0] === "Achieved") {
                        let workType = nameParts[1];
                        let dateStr = nameParts[2];
                        let date = new Date(dateStr);
        
                        if (!tempObj[workType] || new Date(tempObj[workType].date) < date) {
                            tempObj[workType] = { work: workType, measurement: e.measurement, date: dateStr };
                        }
                    }
                });
        
                tempArray = Object.values(tempObj);
                // console.log(tempArray,'temparray')
                let totalMeasurement = 0;
                tempArray.forEach(item => {
                    totalMeasurement += item.measurement;
                });
                // console.log(temp.length)
                let averageMeasurement = totalMeasurement / temp.length;
                this.featureObject[project.item.split(', ')[1]] = averageMeasurement.toFixed(2)

                this.tableData.forEach(element => {
                  // element['Start Date']  = temp2Obj[element['Name of Project'].replace(/,/g, "")]
                  element['completed length1'] = this.featureObject[element['Name of Project'].replace(/,/g, "")]
                })
            
              }
              console.log(this.tableData,"aaaaaaaaa")
            }
            // console.log(featureObject,'featureObject')
          })    
          }
         
          
          temp1Obj[project.item.split(', ')[1]] = project.status;
          tempObj[project.item.split(', ')[1]] = project.completionDate;
          if (project?.kmlData.length) {
            const name = project.kmlData[0].name.split("_");

            if (name[0] === "Planned") {
              console.log(project.length,"project.siteid")
              if( project.siteid == '65769735d0c2613eea0714f1'){

                this.totalLength = this.totalLength + Number(project.kmlData[0].measurement);
                this.projectRetendered = this.projectRetendered + 1;

                console.log(this.projectRetendered,'this.totalLength')
           
              }
              else if( project.siteid == '65827f42b52fd60ebf0a8d12'){
                this.totalLength = this.totalLength + Number(project.kmlData[0].measurement);
                // this.projectRetendered = this.projectRetendered + 1;
                console.log(this.totalLength,'this.totalLength')
              }
              re.obj[project.item.split(', ')[1]] = project.kmlData[0].measurement;
              
            }
          }
          console.log(temp1Obj,'dsadsadsa')
        });
      // });
    
      console.log(temp1Obj,'===============')
      this.tableData.forEach(element => {
        // console.log(his.featureObject[element['Name of Project'].replace(/,/g, "")])
        element['Status'] = temp1Obj[element['Name of Project'].replace(/,/g, "")]
        element['End Date'] = tempObj[element['Name of Project'].replace(/,/g, "")]
        element['Total Planned length of roads (in m)'] = this.obj[element['Name of Project'].replace(/,/g, "")];
        // element['completed length1'] = this.featureObject[element['Name of Project'].replace(/,/g, "")]
      });  
      console.log(temp1Obj,'temp1obj') 
      console.log(this.tableData,'sdsadsadsa')
      });



    })
  }

  getCompletedLength(){
    this.obj = {}
    let re = this
    this.sites.forEach((res) => {
      this.mapService.getProjects(res.id)
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe(async(projects)=>{
        await projects.forEach(async (project: any) => {
          if (project?.tempKmlData.length) {
              let tempArray = [];
              let tempObj = {};
      
              project.tempKmlData.forEach((e: any) => {
                  let nameParts: any = e.name.split("_");
                  if (nameParts[0] === "Achieved") {
                      let workType = nameParts[1];
                      let dateStr = nameParts[2];
                      let date = new Date(dateStr);
      
                      if (!tempObj[workType] || new Date(tempObj[workType].date) < date) {
                          tempObj[workType] = { work: workType, measurement: e.measurement, date: dateStr };
                      }                   
                  }
              });         
             
              tempArray = Object.values(tempObj);
      
              this.tableData.forEach(element => {
                  if (element['Name of Project'].replace(/,/g, "") === project.item.split(', ')[1]) {
                      element['completed length'] = tempArray;
                  }
                  // console.log(tempArray,'tempArray')
                  // element['completed length'] = this.obj[element['Name of Project'].replace(/,/g,"")]

              });
                // re.obj[project.item.split(', ')[1]] = tempArray
          }
      });
      
        // console.log(re.obj)
      })
    })


  }


  filterTableData(a){
    console.log(a,'sdadsad')
    return;
    let temp = []
    this.sites.forEach((res) => {

      if(localStorage.getItem('email') === 'osnagarnigam@rediffmail.com' || localStorage.getItem('email') === 'vishvaraj@heliware.co.in'){
        this.mapService.getProjects(res.id)
        .pipe(takeUntil(this.unsubscribeSubject))
        .subscribe((res1) => {
          console.log(res1)
          res1.forEach(ele => {
            this.tableData.map((e)=>{
              
              console.log(e['Name of Project'].replace(/,/g, "") ,",====",ele.item.split(', ')[1])
              if(e['Name of Project'].replace(/,/g, "") === ele.item.split(', ')[1])
              {
                console.log(e,"in")
                temp.push(e)
              }
            })
          })    
      })
        

      }else{
        this.mapService.fetchAssignedProjects({userid:localStorage.getItem('id'),siteid:res.id}).subscribe((resp:any)=>{
          resp.forEach(ele => {
            this.tableData.map((e)=>{
              console.log(e['Name of Project'].replace(/,/g, "") ,",====",ele.item.split(', ')[1])
              if(e['Name of Project'].replace(/,/g, "") === ele.item.split(', ')[1])
              {
                console.log(e,"in")
                temp.push(e)
              }
            })
          })
        })
      }
      
      // this.mapService.getProjects(res.id)
      //   .pipe(takeUntil(this.unsubscribeSubject))
      //   .subscribe((res1) => {
      //     console.log(res1)
      //     res1.forEach(ele => {
      //       this.tableData.map((e)=>{
      //         console.log(e['Name of Project'].replace(/,/g, "") ,",====",ele.item.split(', ')[1])
      //         if(e['Name of Project'].replace(/,/g, "") === ele.item.split(', ')[1])
      //         {
      //           console.log(e,"in")
      //           temp.push(e)
      //         }
      //       })
      //     })
          
      // })
    })
    console.log(temp)
    this.tableData = temp;
    // this.overAllTabledata = this.tableData
    // console.log(a,"eeeeeee" ,this.assignedProjects)  
    // this.tableData.map((e)=>{
    //   a.forEach(ele => {
    //     console.log(ele ,',====',e)
    //   //   if (e.id === ele.id) {
    //   //     console.log(ele, e, "Common element found!");
    //   // }
    //   });
    // })
  }

}



